<template>
    <div class="text-right">
        <v-dialog
                v-model="isModalOpen"
                persistent
                width="600"
        >
            <v-card>
                <v-card-title class="headline">
                    Новый объект
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="kadnumber"
                                        label="Кадастровый номер"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-radio-group
                                        v-model="addressGroup"
                                        row
                                >
                                    <v-radio
                                            label="Стандартный формат"
                                            value="1"
                                    ></v-radio>
                                    <v-radio
                                            label="Произвольный формат"
                                            value="2"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <div v-show="addressGroup == '1'">
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="adressregion"
                                        label="Регион"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="adressdistrict"
                                        label="Район"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="adresssettlementpoint"
                                        label="Населенный пункт"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="adressstreet"
                                        label="Улица"
                                ></v-text-field>
                            </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="adresshouse"
                                            label="Дом"
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                        </div>

                        <div v-show="addressGroup == '2'">
                            <v-row>
                                <v-col>
                                    <v-textarea
                                            v-model="adressoptionalformat"
                                            label="Произвольный формат адреса"
                                            rows="2"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </div>

                        <v-row>
                            <v-col>
                                <v-btn
                                        class="mr-3 mt-4"
                                        color="primary"
                                        depressed
                                        :loading="loading"
                                        @click="submitForm"
                                >
                                    Создать
                                </v-btn>
                                <v-btn
                                        class="mt-4"
                                        color="primary"
                                        outlined
                                        @click="closeDialog"
                                        depressed
                                >
                                    Отмена
                                </v-btn>
                            </v-col>
                        </v-row>


                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import RealestateDataService from "@/services/RealestateDataService";
    export default {
        name: "AddRealestateForm",
        data () {
            return {
                addressGroup: '1',
                loading: false,
                kadnumber: null,
                adressregion: null,
                adressdistrict: null,
                adresssettlementpoint: null,
                adressstreet: null,
                adresshouse: null,
                adressoptionalformat: null,
            }
        },
        computed: {
            // Проверка состояния диалога открыт/закрыт в VUEX
            isModalOpen() {
                return this.$store.getters.isAddRealestateDialogOpen;
            }
        },

        watch: {
            isModalOpen(newValue) {
                if (newValue) {
                    //this.resetForm() // Сбрасываем форму при открытии диалога
                }
            }
        },

        methods: {

            resetForm() {
                this.addressGroup = '1',
                this.loading = false,
                this.kadnumber = null,
                this.adressregion = null,
                this.adressdistrict = null,
                this.adresssettlementpoint = null,
                this.adressstreet = null,
                this.adresshouse = null,
                this.adressoptionalformat = null
            },

            //Пользовательское событие при успешном сохранении. Это событие слушаем в родительском компоненте
            //realestateid - id созданного клиента
            createEvent(realestateid) {
                this.closeDialog()
                this.$emit('create-realestate-event', realestateid)
            },

            closeDialog() {
                this.$store.dispatch('closeAddRealestateDialog');
            },

            submitForm: function () {

                    this.loading = true
                    var data = {
                        estatetype: 'ZU',
                        kadnumber: this.kadnumber,
                        adressregion: this.adressregion,
                        adressdistrict: this.adressdistrict,
                        adresssettlementpoint: this.adresssettlementpoint,
                        adressstreet: this.adressstreet,
                        adresshouse: this.adresshouse,
                        adressoptionalformat: this.adressoptionalformat,
                        addressformat: this.addressGroup,
                    }

                        RealestateDataService.create(data)
                        .then (response => {
                            console.log(response.data)
                            //this.$router.push({ name: 'EditRealestates', params: { id : response.data.id }})
                            this.createEvent(response.data.id);
                        })
                        .catch(error => {
                            console.log(error);
                        });

            },

        }
    }
</script>

<style scoped>

</style>