<template>
    <div>

        <!--      Диалоговое окно добавления сотрудника к роли-->
        <v-dialog
                eager
                max-width="600"
                persistent
                v-model="addEmployeeDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    <span>Добавление сотрудника</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeAddEmployeeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        label="Выберите сотрудника"
                                        v-model="chosedEmployeeID"
                                        :items="employeesItems"
                                        item-value="employeesid"
                                        item-text="fullname"
                                        clearable
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeAddEmployeeDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="addEmployeeToRole"
                    >
                        Добавить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-row>
            <v-col cols="auto">
                <h1 class="data-name">
                    Роль: {{ role.name }}
<!--                    <v-icon-->
<!--                            @click="editNumberAndDate"-->
<!--                            right-->
<!--                    >-->
<!--                        mdi-pencil-->
<!--                    </v-icon>-->
                </h1>

            </v-col>
        </v-row>



        <v-row>
            <v-col cols="auto">
                <div class="mt-5">
                    <v-card
                            outlined
                            class="mb-4"
                    >
                        <v-card-title>
                            Пользователи
                        </v-card-title>
                        <v-card-text>
                            <div>
                                <v-row justify="start">
                                    <v-col v-for="item in users" :key="item.employeeid" md="4" class="outer">
                                        <v-avatar
                                                size="50"
                                                class="float-left"
                                        >
                                            <img
                                                    :src="`${avatars}${item.avatar}`"
                                            >
                                        </v-avatar>
                                        <div class="avatar-text" style="float: left">
                                            <p class="fio">{{ item.name }} {{ item.surname }}</p>
                                            <p class="post">{{ item.post }}</p>
                                        </div>
                                        <v-icon
                                                class="float-right"
                                                @click="deleteEmployeeFromRole(item.employeeid)"
                                                style="color: rgb(13, 71, 161);"
                                                size="20"
                                        >
                                            mdi-trash-can-outline
                                        </v-icon>
                                    </v-col>
                                    <v-col>
                                        <v-icon
                                                style="color:#b0b0b0"
                                                size="40"
                                                @click="showAddEmployeeDialog"
                                        >
                                            mdi-account-plus-outline
                                        </v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                    </v-card>


                    <v-card
                            elevation="0"
                    >
                        <v-card-title>Договор</v-card-title>
                        <v-card-text>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ORDER_CREATE"
                                    label="Создание договора"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ORDER_UPDATE"
                                    label="Редактирование договора"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ORDER_READ"
                                    label="Просмотр договора"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ORDER_DELETE"
                                    label="Удаление договора"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ORDERS_LIST_READ"
                                    label="Просмотр списка договоров (только свои)"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ORDER_STATUS_UPDATE"
                                    label="Изменение статуса договора"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ECONOMY_READ"
                                    label="Просмотр экономических показателей"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ACCOUNTING_READ"
                                    label="Просмотр вкладки бухгалтерии"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="SALARY_READ"
                                    label="Просмотр заработной платы"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <div class="mt-5">
                    <v-card
                            elevation="0"
                    >
                        <v-card-title>Клиент</v-card-title>
                        <v-card-text>
                            <v-checkbox
                                    v-model="permissions"
                                    value="CUSTOMER_CREATE"
                                    label="Создание клиента"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="CUSTOMER_UPDATE"
                                    label="Редактирование клиента"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="CUSTOMER_READ"
                                    label="Просмотр клиента"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="CUSTOMER_DELETE"
                                    label="Удаление клиента"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="CUSTOMERS_LIST_READ"
                                    label="Просмотр списка клиентов"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <div class="mt-5">
                    <v-card
                            elevation="0"
                    >
                        <v-card-title>Роль</v-card-title>
                        <v-card-text>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ROLES_LIST_READ"
                                    label="Просмотр списка ролей"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ROLE_READ"
                                    label="Просмотр роли"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ROLE_UPDATE"
                                    label="Изменение роли"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <div class="mt-5">
                    <v-card
                            elevation="0"
                    >
                        <v-card-title>Предложение</v-card-title>
                        <v-card-text>
                            <v-checkbox
                                    v-model="permissions"
                                    value="OFFERS_LIST_READ"
                                    label="Просмотр списка предложений"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="OFFER_STATUS_UPDATE"
                                    label="Изменение статуса предложения"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <div class="mt-5">
                    <v-card
                            elevation="0"
                    >
                        <v-card-title>Отчет</v-card-title>
                        <v-card-text>
                            <v-checkbox
                                    v-model="permissions"
                                    value="REPORTS_LIST_READ"
                                    label="Просмотр списка отчетов"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <div class="mt-5">
                    <v-card
                            elevation="0"
                    >
                        <v-card-title>Акт сдачи работ</v-card-title>
                        <v-card-text>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ACTS_LIST_READ"
                                    label="Просмотр списка актов сдачи работ"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <div class="mt-5">
                    <v-card
                            elevation="0"
                    >
                        <v-card-title>Расходы</v-card-title>
                        <v-card-text>
                            <v-checkbox
                                    v-model="permissions"
                                    value="DOCUMENTS_LIST_READ"
                                    label="Просмотр списка расходов"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="DOCUMENT_READ"
                                    label="Просмотр расходника"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <div class="mt-5">
                    <v-card
                            elevation="0"
                    >
                        <v-card-title>Счет на оплату</v-card-title>
                        <v-card-text>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ACCOUNTS_LIST_READ"
                                    label="Просмотр списка счетов на оплату"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="permissions"
                                    value="ACCOUNT_STATUS_UPDATE"
                                    label="Изменение статуса счета на оплату"
                                    hide-details
                                    @change="onPermissionChange"
                            ></v-checkbox>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>

        <v-btn
                @click="savePermissions"
                color="primary"
                :disabled="isDisabledSaveButton"
                class="mb-10"
        >
            Сохранить
        </v-btn>


    </div>






</template>
<script>
import RolesDataService from "@/services/RolesDataService";
import EmployeeDataService from "@/services/EmployeeDataService";

export default {
      name: 'EditRole',

      data() {
            return {
                permissions: [],
                role: {},
                snackbarSave: false,
                alertError: false,
                valid: true,
                isDisabledSaveButton: true,
                users: [], //Сотрудники, привязанные к роли
                avatars: process.env.VUE_APP_AVATARS,
                employeesItems: [],
                addEmployeeDialog: false,
                chosedEmployeeID: null,
            }
        },

        methods: {
              getRole(id) {
                   RolesDataService.get(id)
                    .then(response => {
                        this.role = response.data
                        this.permissions = this.role.permissions.map(permissions => permissions.name) //Формируем массив пермишенов
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

            //Сохранение пермишинов для текущей роли
            savePermissions() {
                let permissions = {permissions: this.permissions}
                RolesDataService.savePermissions(this.role.id, permissions)
                    .then(response => {
                        this.isDisabledSaveButton = true
                        console.log(response)
                        this.$store.dispatch('showAlert', {
                            type: 'warning',
                            message: 'Данные сохранены. Для вступления изменений в силу необходимо перелогинится'
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

            onPermissionChange() {
                this.isDisabledSaveButton = false; // Активируем кнопку при изменении разрешений
            },

            getUsers(roleid) {
                RolesDataService.getUsers(roleid)
                    .then(response => {
                        this.users = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            //ДОбавление сотрудника к роли
            showAddEmployeeDialog() {
                this.getEmployees() //Сотрудники
                this.addEmployeeDialog = true
            },

            closeAddEmployeeDialog() {
                this.addEmployeeDialog = false
            },

            addEmployeeToRole() {
                RolesDataService.addEmployeeToRole(this.$route.params.id, this.chosedEmployeeID)
                    .then(response => {
                        this.getUsers(this.$route.params.id)
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
                this.closeAddEmployeeDialog()
            },
            //Удаление Сотрудника из роли
            deleteEmployeeFromRole(employeeid) {
                RolesDataService.deleteEmployeeFromRole(this.$route.params.id, employeeid)
                    .then(response => {
                        this.getUsers(this.$route.params.id)
                        console.log(response.data);
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            getEmployees() {
                EmployeeDataService.find()
                    .then(response => {
                        this.employeesItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },



        },



        mounted() {
            this.getRole(this.$route.params.id) //Запрос данных документа
            this.getUsers(this.$route.params.id)//Запрос сотрудников, привязанных к роли
        },
    }
</script>
<style scoped>
    .outer .v-icon {
        visibility: hidden;
    }

    .outer:hover .v-icon {
        visibility: visible;
    }

</style>