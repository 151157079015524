<template>
  <v-app>
      <v-navigation-drawer
              v-if="currentUser"
              app
              permanent
              :mini-variant="menu_mini"
              :right="menu_right"
              :expand-on-hover="menu_expand_on_hover"
      >
          <v-list style="padding: 0" v-if="!menu_expand_on_hover">
              <v-list-item class="px-2">
                  <v-btn
                          icon
                          @click.stop="menu_mini = !menu_mini"
                  >
                      <v-icon v-text="menu_mini ? 'mdi-chevron-right' : 'mdi-chevron-left'"></v-icon>
                  </v-btn>
              </v-list-item>
          </v-list>
                  <v-list>
                      <v-list-item class="px-2">
                          <v-list-item-avatar>
<!--                              <v-img-->
<!--                                      :src="`${publicPath}avatars/${currentUser.avatar}`"-->
<!--                              ></v-img>-->
                              <v-img
                                      :src="`${avatars}${currentUser.avatar}`"
                              ></v-img>

                          </v-list-item-avatar>
                          <v-list-item-content>
                                <v-list-item-title style="font-size: 15px; font-weight: 500">
                                    {{ currentUser.userName }} {{ currentUser.userSurName }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ currentUser.post }}</v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-list
                          nav
                          dense
                  >
                      <v-list-item
                              link
                              :to="{ name: 'Dashboard' }"
                      >
                          <v-list-item-icon title="Рабочий стол">
                              <v-icon>mdi-view-dashboard</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Рабочий стол</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                              link
                              :to="{ name: 'Tasks' }"
                              v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                      >
                          <v-list-item-icon title="Задачи">
                              <v-icon>mdi-calendar-check-outline</v-icon>
                              <v-badge
                                      color="red lighten-1"
                                      :content="getTaskOverdueCount"
                                      overlap
                                      style="margin-right: 15px"
                                      v-show="getTaskOverdueCount > 0"
                              > </v-badge>
                          </v-list-item-icon>
                          <v-list-item-title>Задачи</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Employees' }"
                      >
                          <v-list-item-icon title="Сотрудники">
                              <v-icon>mdi-account</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Сотрудники</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Customers' }"
                      >
                          <v-list-item-icon title="Клиенты">
                              <v-icon>mdi-account-multiple</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Клиенты</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Realestates' }"
                      >
                          <v-list-item-icon title="Объекты">
                              <v-icon>mdi-home-city-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Объекты</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Contracts' }"
                      >
                          <v-list-item-icon title="Договоры">
                              <v-icon>mdi-file-sign</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Договоры</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Accounts' }"
                              v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                      >
                          <v-list-item-icon title="Счета">
                              <v-icon>mdi-file-table-outline</v-icon>
                              <v-badge
                                      color="red lighten-1"
                                      :content="getAccountOverdueCount"
                                      overlap
                                      style="margin-right: 15px"
                                      v-show="getAccountOverdueCount > 0"
                              > </v-badge>
                          </v-list-item-icon>
                          <v-list-item-title>Счета</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Acts' }"
                              v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                      >
                          <v-list-item-icon title="Акты сдачи работ">
                              <v-icon>mdi-file-document-check-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Акты сдачи работ</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Calendar' }"
                      >
                          <v-list-item-icon title="Календарь">
                              <v-icon>mdi-car-clock</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Календарь</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Gis' }"
                              v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                      >
                          <v-list-item-icon title="ГИС">
                              <v-icon>mdi-map-search-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>ГИС</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Documents' }"
                              v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                      >
                          <v-list-item-icon title="Расходы">
                              <v-icon>mdi-finance</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Расходы</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Offers' }"
                              v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                      >
                          <v-list-item-icon title="Предложения">
                              <v-icon>mdi-file-document-edit-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Предложения</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Correspondence' }"
                              v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                      >
                          <v-list-item-icon title="Корреспонденция">
                              <v-icon>mdi-email-edit-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Корреспонденция</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                              link
                              :to="{ name: 'Reports' }"
                              v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                      >
                          <v-list-item-icon title="Отчеты">
                              <v-icon>mdi-chart-bar</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Отчеты</v-list-item-title>
                      </v-list-item>
<!--                      <v-list-item-->
<!--                              link-->
<!--                              :to="{ name: 'Marketing' }"-->
<!--                      >-->
<!--                          <v-list-item-icon title="Маркетинг">-->
<!--                              <v-icon>mdi-email-fast-outline</v-icon>-->
<!--                          </v-list-item-icon>-->
<!--                          <v-list-item-title>Маркетинг</v-list-item-title>-->
<!--                      </v-list-item>-->
                  </v-list>

                  <template v-slot:append>
                      <v-list
                              nav
                              dense
                      >
                          <v-list-item
                                  link
                                  :to="{ name: 'Settings'}"
                                  v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                          >
                              <v-list-item-icon title="Настройки">
                                  <v-icon>mdi-tune</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Настройки</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                                  link
                                  @click.prevent="logOut"
                          >
                              <v-list-item-icon title="Выход">
                                  <v-icon>mdi-exit-to-app</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Выход</v-list-item-title>
                          </v-list-item>
                      </v-list>
                  </template>
              </v-navigation-drawer>

    <v-main :class="{background: currentUser, backgroundLogin: !currentUser}">
      <v-container fluid>
          <Alert/><!--Глобальный компонент уведомлений VUEX-->
        <router-view/>
      </v-container>

    </v-main>


    <v-footer
            app
            padless
            fixed
            v-if="currentUser"
    >
      <v-row>
        <v-col
                class="text-right"
                cols="12"
                style="padding: 10px 52px 5px 0"
        >



<!--<span style="text-align: left; font-size: 13px; margin-right: 150px" v-show= "$route.name === 'Contracts'">-->
<!--&lt;!&ndash;    {{ new Date().getFullYear() }} — <strong>{{ currentUser.id}}</strong>&ndash;&gt;-->
<!--    <span style="margin-right: 20px">Стоимость: {{ this.$store.state.count }}</span>-->

<!--    <span style="margin-right: 20px">Оплата: 45 892</span>-->
<!--&lt;!&ndash;    <v-divider&ndash;&gt;-->
<!--&lt;!&ndash;            vertical&ndash;&gt;-->
<!--&lt;!&ndash;    ></v-divider>&ndash;&gt;-->
<!--    <span style="margin-right: 20px">Закрыто: 45 892</span>-->
<!--</span>-->









            <!--Горящие договоры-->

            <FireContractsModal/>

            <!--Дни рождения-->
            <v-dialog
                    v-model="birthdaydialog"
                    width="500"
                    style="position: absolute; left: 0"
            >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                          color="green"
                          :content="birthdaycount"
                          overlap
                          style="margin-right: 15px"
                          v-show="birthdaycount > 0"
                  >
                    <v-icon
                            color="#3f4c6b"
                            size="30"
                            v-bind="attrs"
                            v-on="on"
                    >
                      mdi-cake-variant-outline
                    </v-icon>
                  </v-badge>
                </template>
                <v-card>
                    <v-card-title class="text-h5 red lighten-2" style="color: white">
                        Дни рождения
                    </v-card-title>

                    <v-card-text>
                        <div
                                v-for="item in birthday"
                                :key="item.employeesid"
                                style="margin-top: 15px"
                        >
                            <v-row>
                                <v-avatar
                                        size="50"
                                        class="float-left"
                                >
                                    <img
                                            :src="`${avatars}${item.avatar}`"
                                    >
                                </v-avatar>
                                <div class="float-left mr-7" >
                                    <div class="avatar-text">
                                        <p class="fio">{{ item.name }} {{ item.surname }}</p>
                                        <p class="post">{{ item.post }}</p>
                                        <p>Дата рождения: {{ item.birthday.date | formatDate() }}</p>
                                    </div>

                                </div>
                            </v-row>
                            <v-divider></v-divider>

                        </div>


                    </v-card-text>



                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                text
                                @click="birthdaydialog = false"
                        >
                            Закрыть
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!--Центр уведомлений-->
<!--            <v-badge-->
<!--                    color="green"-->
<!--                    :content="getNoticesCount"-->
<!--                    overlap-->
<!--                    style="margin-right: 15px"-->
<!--            >-->
<!--                <v-icon-->
<!--                    color="#3f4c6b"-->
<!--                    size="30"-->
<!--                >-->
<!--                    mdi-bell-outline-->
<!--                </v-icon>-->
<!--            </v-badge>-->

            <notice-center-modal/>


        </v-col>
      </v-row>

    </v-footer>
  </v-app>


</template>

<script>
 import NoticeDataService from "@/services/NoticeDataService";
 import Notice from "@/components/notices/Notice";
 import EmployeeDataService from "@/services/EmployeeDataService";
 import Alert from "@/components/elements/Alert";
 // import CompanyDataService from "@/services/CompanyDataService";
 import NoticeCenterModal from "@/components/notices/NoticeCenterModal";
 import FireContractsModal from "@/components/Order/FireContractsModal";
 import http from "../src/http-common"


 export default {
     name: 'App',
     components: {FireContractsModal, NoticeCenterModal, Alert},
     data () {
     return {
         menu_expand_on_hover: false,
         menu_right: false,
         menu_mini: false,
         notices: null,
         birthday:[],
         birthdaycount: 0,
         dialog: false,
         birthdaydialog: false,
         populLoad : 'popupLoad',
         publicPath: process.env.BASE_URL,
         avatars: process.env.VUE_APP_AVATARS,
         VUE_APP_TENANT: process.env.VUE_APP_TENANT
     }
   },
   computed:  {
       currentUser() {
            return this.$store.state.auth.user
       },

       //Количество Просроченные задачи
       getTaskOverdueCount() {
           return this.$store.state.task.taskOverdueCount
       },

       //Количество Просроченные счетов
       getAccountOverdueCount() {
           return this.$store.state.account.accountOverdueCount
       },
   },
   methods: {
     logOut() {
       this.$store.dispatch('auth/logout');
       this.$router.push('/login');
     },

       showNotice(item) {
           const content = {
               component: Notice,
               props: {
                   noticeobj: item //Объект уведомления
               },
               listeners: {
                   deletenotice: () => {
                       this.getShowOnScreenNotices()
                       this.getNoticeCount()
                   }
               }
           }
           this.$toast(content, {closeButton: false});
       },

       //Уведомления на экране
       getShowOnScreenNotices() {
            NoticeDataService.getShowOnScreenNotices(this.$store.state.auth.user.userid)
               .then( response => {
                    this.notices = response.data
                    this.$toast.clear();//Удаляем все уведомления с экрана, чтобы к ним не добавилось
                    this.notices.forEach((item) => {
                    this.showNotice(item)
                 });

               })
               .catch(error => console.log(error));
       },


       getNoticeCount() {
           this.$store.dispatch('noticesCount', this.$store.state.auth.user.userid)
       },

       //Количество горящих договоров
       getFireContractsCount() {
           this.$store.dispatch('fireContractsCount')
       },

       //Количество просроченнных счетов
       getAccountOverdue() {
           this.$store.dispatch('accountOverdueCount')
       },

       getPopupTime(popupLoad) {
           let time = new Date().getTime();
           if (localStorage.getItem(popupLoad)) {
               time -= localStorage.getItem(popupLoad);
               console.log(time);
           } else {
               localStorage.setItem(popupLoad, time);
           }
           return time;
       },

       //Дни рождения выбираем за 3 дня
       getBirthday () {
           EmployeeDataService.getbirthday()
               .then( response => {
                   this.birthday = response.data
                   this.birthdaycount = this.birthday.length
               })
               .catch(error => console.log(error));
       },

       //Количество просроченных задач
       getTaskOverdue() {
           this.$store.dispatch('taskOverdueCount', this.$store.state.auth.user.userid)
       },

       //Количество задач в работе
       getTaskInWork() {
           this.$store.dispatch('taskInWorkCount', this.$store.state.auth.user.userid)
       },
   },

   mounted() {
       this.getTaskOverdue() //Просроченные задачи
       this.getTaskInWork() //Задачи в работе
       this.getFireContractsCount() //Количество горящих договоров
       this.getAccountOverdue() //Количество просроченных счетов
       this.getNoticeCount() //Количество уведомлений в центре уведомлений
       this.getBirthday() //Дни рождения
       this.getShowOnScreenNotices()

       setInterval(() => this.getTaskOverdue(), 43200000);// повторить с интервалом 12 час
       setInterval(() => this.getTaskInWork(), 1800000);// повторить с интервалом 30 мин
       setInterval(() => this.getFireContractsCount(), 43200000);// повторить с интервалом 12 час
       setInterval(() => this.getNoticeCount(), 1800000);// повторить с интервалом 30 мин
       setInterval(() => this.getBirthday(), 43200000);// повторить с интервалом 12 час
       setInterval(() => this.getShowOnScreenNotices(), 600000);// повторить с интервалом 10 мин


        // Считываем настройки
       if (localStorage.menu_right) {
           this.menu_right = JSON.parse(localStorage.getItem('menu_right'))
       } else
       {
           this.menu_right = false; //По умолчанию, если нет запичи в сторадже
       }

       if (localStorage.menu_expand_on_hover) {
           this.menu_expand_on_hover = JSON.parse(localStorage.getItem('menu_expand_on_hover'))
       } else
       {
           this.menu_expand_on_hover = false; //По умолчанию, если нет запичи в сторадже
       }

       if (localStorage.menu_mini) {
           this.menu_mini = JSON.parse(localStorage.getItem('menu_mini'))
       } else
       {
           this.menu_mini = false; //По умолчанию, если нет запичи в сторадже
       }

   },


     created() {
         //Проверяем, если срок действия токена закончился (API выдает ошибку 401) - перебрасываем на страницу ввода логина
         http.interceptors.response.use(
             response => response,
             error => {
                 const {status} = error.response;
                 if (status === 401) {
                     this.logOut()
                 }
                 return Promise.reject(error);
             }
         )
     },

     filters: {
         formatDate: function (datetime) {
             return [datetime.slice(8,10),datetime.slice(5,7),datetime.slice(0,4)].join('.')
         },
         formatDateDiff: function (datediff) {
             if (datediff >= 0) {
                 return `Осталось ${datediff} дн.`
             }
             if (datediff < 0) {
                 return `Просрочено ${Math.abs(datediff)} дн.`
             }
         },
     },

     watch: {
         menu_mini(newValue) {
             localStorage.menu_mini = newValue;
         }
     }


 }

</script>

<style lang="scss">
  .background {
    background-image: url('assets/images/fon.png');
    background-repeat: repeat;
  }

  /*Класс используется если мы на странице ввода логина/пароля*/
  .backgroundLogin {
    background-image: url('assets/images/wallpaper.jpg');
    background-repeat: no-repeat;
    background-position: center;
  }

  .ol-mouse-position {
    background-color: rgba(0,60,136,.5);
    border-radius: 4px;
    color: #eee;
    padding: 3px 6px;
  }

  .blocklink {
      cursor: pointer;
  }
  .blocklink:hover {
      color: #0d47a1;
  }


  .orderdeadlinered {
      background-color: #ff4949;
  }

  .orderdeadlineorange {
      background-color: orange;
  }

  /*start style v-tooltip*/

  $color: rgba(#1190c4, .8);

  .tooltip {
    display: block !important;
    z-index: 10000;
    font-family: "Roboto", sans-serif;
      font-size: 13px;
      font-weight: 300;
  }

  .tooltip .tooltip-inner {
    background: $color;
    color: white;
    padding: 3px 6px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, .1);
    max-width: 250px;
  }

  .tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: $color;
    z-index: 1;
  }

  .tooltip[x-placement^="top"] {
    margin-bottom: 5px;
  }

  .tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="bottom"] {
    margin-top: 5px;
  }

  .tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="right"] {
    margin-left: 5px;
  }

  .tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip[x-placement^="left"] {
    margin-right: 5px;
  }

  .tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip.popover .popover-inner {
    background: #f9f9f9;
    color: black;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, .1);
  }

  .tooltip.popover .popover-arrow {
    border-color: #f9f9f9;
  }

  .tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  .tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
  /* end style v-tooltip*/

  .avatar-text {
      padding-left: 20px;
  }
  .fio {
      margin-bottom: 0 !important;
      font-size: 0.875rem;
      color: rgba(0, 0, 0, 0.87);
  }
  .post{
      font-size: 12px;
      color: #686868;
      margin-bottom: 0 !important;
      line-height: 15px;
  }

  .editdialog {
      position: absolute;
      top: 0 !important;
      right: 0 !important;
      margin: 0 !important;
      width: 70% !important;
      height: 100% !important;
      max-height: 100% !important;
      border-radius: 0 !important;
  }

  .editdialog .v-sheet.v-card {
      border-radius: 0 !important;
      min-height: 100%;
  }

  .noticecenterstyle {
      margin-right: 20px;
      font-size: 13px;
      text-decoration: none;
      color: #353535;
  }

  .noticecenterstyle p {
      color: #313131;
      font-size: 15px;
      padding-bottom: 5px;
      margin: 0;
      font-weight: 500;
  }

  .noticecenterstyle p span {
      font-size: 11px;
      color: #9c9c9c;
  }

  .noticecenterstyle a {
      color: #92c1ff;
  }
  .noticecenterstyle a:hover {
      color: #538eff;
  }

  .notice-message {
      background-color: #59a230;
      color: white;
      border-radius: 3px;
      padding: 0 3px;
  }

  /*Красный бекграунд текста просроченного срока*/
  .enddeadline {
      display: inline-block;
      background-color: red;
      padding: 2px 5px;
      border-radius: 5px;
      color: white;
  }
  /*Статусы*/
  .isNew {
      background: #44ddbf !important;
      color: white !important;
  }

  .isWork {
      background: #1E88E5 !important;
      color: white !important;
  }

  .isCompleted{
      background: #43A047 !important;
      color: white !important;
  }

  .isRefuse {
      background: #E53935 !important;
      color: white !important;
  }
</style>
