export default {
    state: {
        isAddRealestateDialogOpen: false
    },
    actions: {
        showAddRealestateDialog({ commit }) {
            commit('OPEN_ADD_REALESTATE_DIALOG');
        },
        closeAddRealestateDialog({ commit }) {
            commit('CLOSE_ADD_REALESTATE_DIALOG');
        }
    },
    mutations: {
        OPEN_ADD_REALESTATE_DIALOG(state) {
            state.isAddRealestateDialogOpen= true
        },
        CLOSE_ADD_REALESTATE_DIALOG(state) {
            state.isAddRealestateDialogOpen = false
        }
    },

    getters: {
        isAddRealestateDialogOpen: state => {
            return state.isAddRealestateDialogOpen
        },
    }
}