<template>
    <div>
        <AddContractForm/>

        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Договоры</span>
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn
                        color="primary"
                        depressed
                        @click="printTable"
                        class="mr-3"
                >
                    Печать
                </v-btn>
                <v-btn
                        color="primary"
                        depressed
                        v-if="$can('ORDER_CREATE', 'all')"
                        @click="addContract"
                >
                    Создать
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                        <v-form
                                v-model="valid"
                                lazy-validation
                        >
                            <v-text-field
                                    label="Номер договора"
                                    placeholder="Введите данные для поиска"
                                    v-model="contractNumberString"
                                    @keyup.enter="get"
                            ></v-text-field>
                            <v-text-field
                                    label="Заказчик"
                                    placeholder="Введите данные для поиска"
                                    v-model="customerString"
                                    @keyup.enter="get"
                                    hint="Несколько слов разделять пробелом"
                            ></v-text-field>
                            <v-text-field
                                    label="Адрес"
                                    placeholder="Введите данные для поиска"
                                    v-model="addressString"
                                    @keyup.enter="get"
                                    hint="Несколько слов разделять пробелом"
                            ></v-text-field>


                                <v-row>
                                    <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                                <v-text-field
                                                        label="Дата договора"
                                                        placeholder="Начало"
                                                        v-model="startContractDate"
                                                        v-mask="'##.##.####'"
                                                        :rules="dateRules"
                                                        ref="startContractDate"
                                                ></v-text-field>
                                    </v-col>

                                    <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                        <v-text-field
                                                placeholder="Окончание"
                                                v-model="endContractDate"
                                                v-mask="'##.##.####'"
                                                :rules="dateRules"
                                                ref="endContractDate"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                        <v-text-field
                                                label="Срок договора"
                                                placeholder="Начало"
                                                v-model="startDeadlineDate"
                                                v-mask="'##.##.####'"
                                                :rules="dateRules"
                                                ref="startDeadlineDate"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                        <v-text-field
                                                placeholder="Окончание"
                                                v-model="endDeadlineDate"
                                                v-mask="'##.##.####'"
                                                :rules="dateRules"
                                                ref="endDeadlineDate"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col style="padding-top: 0; padding-bottom: 0">
                                        <v-autocomplete
                                                label="Руководитель проекта"
                                                v-model="managerid"
                                                :items="employeesItems"
                                                item-value="employeesid"
                                                item-text="fullname"
                                                clearable
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            <v-row>
                                <v-col style="padding-top: 0; padding-bottom: 0">
                                    <v-autocomplete
                                            label="Участник проекта"
                                            v-model="memberid"
                                            :items="employeesItems"
                                            item-value="employeesid"
                                            item-text="fullname"
                                            clearable
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                                <v-row>
                                    <v-col style="padding-top: 0; padding-bottom: 15px">
                                        <div class="data-label">Статус</div>
                                        <div v-for="item in status" :key="item.val">
                                            <v-checkbox
                                                    v-model="statusArr"
                                                    :value="item.val"
                                                    :label="`${item.text}`"
                                                    hide-details="true"
                                            ></v-checkbox>
                                        </div>
                                    </v-col>
                                </v-row>
                            <v-row>

                                <v-col style="margin-bottom: 50px; padding-top: 0;">
                                    <div class="data-label">Дополнительные фильтры</div>
                                    <v-checkbox
                                            v-model="favorite"
                                            style="margin-top: 3px"
                                            label="Избранные"
                                            hide-details
                                    ></v-checkbox>
                                </v-col>

                            </v-row>
                                <v-row
                                        align="center"
                                        justify="space-around"
                                >
                                    <v-btn
                                            depressed
                                            @click="resetForm"
                                            class="mb-3"
                                    >
                                        Сбросить
                                    </v-btn>
                                    <v-btn
                                            :disabled="!valid"
                                            depressed
                                            color="primary"
                                            @click="get"
                                            class="mb-3"
                                    >
                                        Применить
                                    </v-btn>
                                </v-row>
                        </v-form>
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col cols="10">



<!--                <v-card>-->
<!--                    <v-card-text>-->
<!--                        <div-->
<!--                                v-for="item in items"-->
<!--                                :key="item.orderid"-->
<!--                        >-->
<!--                            <p style="font-weight: 500; color: #2b2b2b; margin-bottom: 10px; margin-top: 10px">Договор №{{ item.contractnumber }} от {{ item.orderdata.date | formatOrderDate }}-->
<!--                                <v-icon-->
<!--                                        size="19"-->
<!--                                >-->
<!--                                    mdi-star-outline-->
<!--                                </v-icon>-->

<!--                            </p>-->
<!--                            <p style="margin: 0; padding: 0">{{ item.customerkod.fullname }}</p>-->
<!--                            <p>{{ item.estatecod.fulladdress }}</p>-->
<!--                            <v-divider></v-divider>-->
<!--                        </div>-->
<!--                    </v-card-text>-->
<!--                </v-card>-->


                <v-data-table
                        :headers="computedHeaders"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                        @dblclick:row="toContract"
                        title="Двойной щелчок - Просмотреть"
                        id="printableTable"

                >
                            <!--  Код, если хотим форматировать всю строку-->
                            <!--                    <template v-slot:item="{ item}">-->
                            <!--<tr>-->
                            <!--    <td>-->
                            <!--        {{ item.orderdata.date | formatOrderDate }}-->
                            <!--    </td>-->
                            <!--</tr>-->
                            <!--                    </template>-->

<!--                    items.indexOf(item)  - офигенный метод поиска кода строки-->
                    <template  v-slot:item.favoriteforemployer="{ item }">
                        <v-icon
                                size="19"
                                @click="addToFavorite(items.indexOf(item), item.orderid)"
                                v-show="(item.favoriteforemployer == NULL) ||(item.favoriteforemployer != $store.state.auth.user.userid) "
                        >
                            mdi-star-outline
                        </v-icon>
                        <v-icon
                                size="21"
                                @click="deleteFromFavorite(items.indexOf(item), item.orderid)"
                                color="#f4942d"
                                v-show="(item.favoriteforemployer != NULL) &&  (item.favoriteforemployer == $store.state.auth.user.userid)"
                        >
                            mdi-star
                        </v-icon>
                    </template>

                    <template v-slot:item.contractnumber="{ item }">
                        <span>
                        <router-link
                                v-tooltip="{ content: item.ordertarget}"
                                class="itemlink" :to="{ name: 'EditContract', params: { id: item.orderid }}">
                            {{ item.contractnumber }}
                        </router-link>
                        </span>
                    </template>


                    <template v-slot:item.orderdata.date="{ item }">
                        {{ item.orderdata.date | formatOrderDate}}
                    </template>
                    <template v-slot:item.orderdeadline.date="{ item }">
                        {{ item.orderdeadline.date | formatOrderDeadline}}
                        <v-progress-linear
                                v-if="item.orderstatus == 0"
                                :value="calculateProcent(new Date(item.orderdata.date), new Date(item.orderdeadline.date))"
                                height="2"
                                :color="getProgressColor(item.orderdata.date, item.orderdeadline.date)"
                                background-color="grey lighten-4"
                                rounded
                        >
<!--                            <span style="font-size: 9px; ">Осталось 30 дн.</span>-->
                        </v-progress-linear>
                    </template>

<!--                    <template v-slot:item.orderstatus="{ item }">-->
<!--                        <v-avatar-->
<!--                                :color="getColor(item.orderstatus)"-->
<!--                                size="8"-->
<!--                        ></v-avatar>-->
<!--                    </template>-->

                    <template v-slot:item.orderstatus="{ item }">
                        <order-stepper :orderstatus="item.orderstatus" :orderid="item.orderid"/>
                    </template>


                    <template v-slot:item.customerkod.fullname="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditCustomer', params: { id: item.customerkod.customerid }}">{{ item.customerkod.fullname }}</router-link>
                    </template>

                    <template v-slot:item.estatecod.fulladdress="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditRealestates', params: { id: item.estatecod.estateid }}">{{ item.estatecod.fulladdress }}</router-link>
                    </template>

                    <!--Сумма по договору-->
                    <template v-slot:item.tablecrossingservicevieworders="{ item }">
                        <div style="text-align: right">
                            {{ bill = new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(getBillSumm(item.tablecrossingservicevieworders)) }}
                        </div>
                    <!--Выводим список сумм с разбивкой по видам деятельсности-->
                        <div
                                v-for="(value, name) in getContractsSummByActivities(item.tablecrossingservicevieworders)"
                                :key="value"
                        >
                            <span
                                    :class="{ igi: name == 'ИГИ', bur: name == 'Бурение'}"
                            >
                                {{ name }} : {{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(value) }}
                            </span>
                        </div>
                    </template>

                    <!--Оплата-->
                    <template v-slot:item.credit="{ item }">
                        <!-- Некрасивое Решение в лоб. Желательно переписать-->
                        <div style="text-align: right">
                        <span style="display: none">{{ credit = new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(Number(getCreditSumm(item.credit))) }}</span>
                        <span :class="{ reportwarning : bill !== credit}"> {{ credit }} </span>
                        </div>
                    </template>

                    <!--Закрыто-->
                    <template v-slot:item.acttable.tablecrossingserviceviewact="{ item }">
                        <!-- Некрасивое Решение в лоб. Желательно переписать-->
                        <div style="text-align: right">
                        <span style="display: none">{{ act = new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(getActSumm(item.acttable)) }}</span>
                        <span :class="{ reportwarning : bill !== act}"> {{ act }} </span>
                        </div>
                    </template>

                    <!--ЗП (Начислено/Неначислено)-->
                    <template v-slot:item.ordersalaryflag="{ item }">
                        <v-icon
                                size="40"
                                color="green"
                                v-show="item.ordersalaryflag === true"
                        >
                            mdi-circle-small
                        </v-icon>
                    </template>

                    <template slot="body.append"
                    >
                        <tr v-show="$store.state.auth.user.roles[0] == 'ROLE_SUPER_ADMIN'">
                            <th>Всего:</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th style="text-align: right">{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(getContractsSumm())}}</th>
                            <th style="text-align: right">{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(getCreditsSum())}}</th>
                            <th style="text-align: right">{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(getActsSumm())}}</th>
                        </tr>
                    </template>


                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import OrderDataService from "@/services/OrderDataService";
    import AddContractForm from "@/components/AddContractForm";
    import OrderStepper from "@/components/elements/OrderStepper";
    import EmployeeDataService from "@/services/EmployeeDataService";


    export default {
        name: "ContractsTable",
        components: {OrderStepper, AddContractForm},
        data () {
            return {
                //Cтатусы договора, потом этот код перенести в отдельный файл, чтобы
                //потом подгружать статусы из различных компонентов, или
                //вообще реализовать статусы в базе данных
                // orderStatus: [
                //     { text: 'Любой', val: '-1' },
                //     { text: 'В работе', val: '0' },
                //     { text: 'Выполнен', val: '1' },
                //     { text: 'Отказ', val: '3' },
                // ],


                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD',
                },
                status: [
                    { text: 'Новый', val: '4' },
                    { text: 'В работе', val: '0' },
                    { text: 'Выполнен', val: '1' },
                    { text: 'Отказ', val: '3' },
                ],
                statusArr: [],
                favorite: false,
                userRoles: null,
                startContractDate: '',
                endContractDate: '',
                startDeadlineDate: '',
                endDeadlineDate: '',

                valid: true,

                maxResults: 0,

                contractNumberString: '', //Строка поиска - номер договора
                customerString: '', //Строка поиска - заказчик
                addressString: '', //Строка поиска - адрес

                loading: true,
                items: [],
                dateRules: [
                    v => (!v || /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(v)) || 'Пожалуйста, введите дату',
                ],

                employeesItems: [], //Сотрудники
                managerid: null, //Выбранный сотрудник в фильтре (руководитель проекта)
                memberid: null, //Выбранный сотрудник в фильтре (участник проекта)
                VUE_APP_TENANT: process.env.VUE_APP_TENANT
            }
        },
        mounted () {
            //read Localstorage
            if (localStorage.contractNumberString) {
                this.contractNumberString = localStorage.contractNumberString
            }
            if (localStorage.customerString) {
                this.customerString = localStorage.customerString
            }
            if (localStorage.addressString) {
                this.addressString = localStorage.addressString
            }
            if (localStorage.startContractDate) {
                this.startContractDate = localStorage.startContractDate
            }
            if (localStorage.startContractDate) {
                this.endContractDate = localStorage.endContractDate
            }
            if (localStorage.startDeadlineDate) {
                this.startDeadlineDate = localStorage.startDeadlineDate
            }
            if (localStorage.endDeadlineDate) {
                this.endDeadlineDate = localStorage.endDeadlineDate
            }
            if (localStorage.status) {
                this.statusArr = JSON.parse(localStorage.getItem('status'))
            }
            if (localStorage.contractMaxResults) {
                this.maxResults = localStorage.contractMaxResults
            } else
            {
                this.maxResults = 50; //По умолчанию, если нет запичи в сторадже
            }
            if (localStorage.favorite) {
                this.favorite = JSON.parse(localStorage.favorite)
            }
            if (localStorage.order_managerid) {
                this.managerid = JSON.parse(localStorage.getItem('order_managerid'))
            }
            if (localStorage.order_memberid) {
                this.memberid = JSON.parse(localStorage.getItem('order_memberid'))
            }




            this.userRoles = this.$store.state.auth.user.roles //Роли зарегистрированного пользователя

            this.getEmployees() //Запрос всех сотрудников для выбора в списке поиска руководителя проекта

            //Запрашиваем данные с сервера
            this.get()
        },
        filters: {
            formatOrderDate: function (orderdate) {
                        return [orderdate.slice(8,10),orderdate.slice(5,7),orderdate.slice(0,4)].join('.')
            },
            formatOrderDeadline: function (orderdeadline) {
                return [orderdeadline.slice(8,10),orderdeadline.slice(5,7),orderdeadline.slice(0,4)].join('.')
            }
        },

        computed: {
            getPermissions() {
                return this.$store.getters['auth/ability']
            },

            computedHeaders () {
                const header =  [
                    {
                        value:'favoriteforemployer',
                        width: 50,

                    },
                    // {
                    //     text: 'Код',
                    //     value: 'orderid',
                    //     width: 72
                    // },
                    {
                        text: 'Номер',
                        value: 'contractnumber',
                        width: 100
                    },
                    {
                        text: 'Дата',
                        value: 'orderdata.date',
                        width: 100
                    },
                    {
                        text: 'Срок',
                        value: 'orderdeadline.date',
                        width: 100
                    },
                    {
                        text: 'Статус',
                        value: 'orderstatus',
                        width: 150,
                    },
                    {
                        text: 'Заказчик',
                        value: 'customerkod.fullname',
                        width: 300
                    },
                    {
                        text: 'Адрес',
                        value: 'estatecod.fulladdress',
                    }]


                // if (this.getPermissions.can('ECONOMY_READ', 'all')) {
                if (this.getPermissions.can('ECONOMY_READ', 'all') && (this.VUE_APP_TENANT !== 'GEOREESTR')) {
                    header.push(
                        {
                            text: 'Стоимость',
                            value: 'tablecrossingservicevieworders',
                            width: 150
                        },
                        {
                            text: 'Оплата',
                            value: 'credit',
                            width: 100
                        },
                        {
                            text: 'Закрыто',
                            value: 'acttable.tablecrossingserviceviewact',
                            width: 100
                        }
                    )
                }

                // if (this.getPermissions.can('ECONOMY_READ', 'all')) {
                if (this.getPermissions.can('ECONOMY_READ', 'all') && (this.VUE_APP_TENANT !== 'GEOREESTR')) {
                    header.push(
                        {
                            text: 'ЗП',
                            value: 'ordersalaryflag',
                            width: 70
                        }
                    )
                }

                return header
            },

        },


        methods: {
            addContract() {
                this.$store.dispatch('showAddContractDialog');
            },

            //Возвращаем класс для Row таблицы (item-class)
            //Не использую пока
            // https://stackoverflow.com/questions/62969944/vuetify-data-table-item-class-doesnt-do-anything
            rowClass() {
                const rowClass = 'rowtitle'
                return rowClass;
            },

            //Сумма заключенного договора в разрезе видов деятельности
            getContractsSummByActivities(array) {
                let rez = {};
                array.map(obj=>{
                    rez[obj.servicekod.activiti] ? rez[obj.servicekod.activiti] += Number(obj.serviceprice * obj.servicecount) : rez[obj.servicekod.activiti] = Number(obj.serviceprice * obj.servicecount)
                })
                return rez
            },

            toContract(event, {item}) {
                this.$router.push({name: 'EditContract', params: { id: item.orderid }})
            },


            getCreditSumm: function(items) {
                let Summ = 0
                items.forEach(function (item) {
                    if (item.isregistered === true) {
                        Summ  = Summ + Number(item.sum)
                    }
                })
                return Summ
            },

            //Сумма в футере
            getCreditsSum() {
                let Summ = 0
                this.items.forEach(function (item) {
                    for (let i = 0; i < item.credit.length; i++)
                    {
                        if (item.credit[i].isregistered === true)
                        Summ  = Summ + Number(item.credit[i].sum)
                    }
                })
                return Summ
            },


            getBillSumm: function(items) {
              let Summ = 0
                items.forEach(function (item) {
                    Summ  = Summ + Number(item.serviceprice * item.servicecount)
                })
                return Summ
            },

            //Сумма в футере
            getContractsSumm() {
                let Summ = 0
                this.items.forEach(function (item) {
                    for (let i = 0; i < item.tablecrossingservicevieworders.length; i++)
                    {
                        Summ  = Summ + Number(item.tablecrossingservicevieworders[i].serviceprice * item.tablecrossingservicevieworders[i].servicecount)
                    }
                })
                return Summ
            },

            //Сумма зарегистрированных актов
            // getActSumm: function(items) {
            //     let Summ = 0
            //     items.forEach(function (item) {
            //         //Summ = Summ + item.actnumber
            //         if (item.isregistered === true) {
            //             for (let i=0; i < item.tablecrossingserviceviewact.length; i++)
            //             {
            //                  Summ = Summ + Number(item.tablecrossingserviceviewact[i].serviceprice * item.tablecrossingserviceviewact[i].servicecount)
            //             }
            //         }
            //     })
            //
            //     return Summ
            // },
            getActSumm: function(items) {
                let Summ = 0
                items.forEach(function (item) {
                    if (item.isregistered === true) {
                        Summ = Summ + Number(item.sum)
                    }
                })

                return Summ
            },

            //Сумма актов в футере
            getActsSumm () {
                let Summ = 0
                this.items.forEach(function (item) {
                    for (let i=0; i < item.acttable.length; i++)
                    {
                        if (item.acttable[i].isregistered === true)
                            Summ = Summ + Number(item.acttable[i].sum)
                    }
                })
                return Summ
            },

            //Добавляем договор в избранное
            addToFavorite(index, orderid) {
                OrderDataService.addContractToFavorite(orderid, this.$store.state.auth.user.userid)
                    .then(response => {
                        this.items[index].favoriteforemployer = this.$store.state.auth.user.userid
                        console.log(response.data)
                     })
                     .catch(e => {
                         console.log(e)
                     })
            },

            //Удаляем договор в избранного
            deleteFromFavorite(index, orderid) {
                OrderDataService.deleteContractFromFavorite(orderid, this.$store.state.auth.user.userid)
                    .then(response => {
                        this.items[index].favoriteforemployer = "null"
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            resetForm: function () {
                this.contractNumberString = ''
                this.customerString = ''
                this.addressString = ''
                this.startContractDate = ''
                this.endContractDate = ''
                this.startDeadlineDate = ''
                this.endDeadlineDate = ''
                this.statusArr = []
                this.favorite = false
                this.managerid = null
                this.memberid = null
            },
            get: function () {
                //Обнуляем суммы договоров в Store, чтобы отобразить в футере
                //this.$store.commit('setcontractssumm' , 0)

                //Save Localstorage
                localStorage.contractNumberString = this.contractNumberString
                localStorage.customerString = this.customerString
                localStorage.addressString = this.addressString
                localStorage.startContractDate = this.startContractDate
                localStorage.endContractDate = this.endContractDate
                localStorage.startDeadlineDate = this.startDeadlineDate
                localStorage.endDeadlineDate = this.endDeadlineDate
                localStorage.setItem('status', JSON.stringify(this.statusArr))
                localStorage.setItem('favorite', JSON.stringify(this.favorite))
                localStorage.setItem('order_managerid', JSON.stringify(this.managerid))
                localStorage.setItem('order_memberid', JSON.stringify(this.memberid))

                this.loading = true

                let memberid;
                if (this.getPermissions.can('ORDERS_LIST_READ', {"owner": true}))  {
                    memberid = this.$store.state.auth.user.userid;
                } else {
                    memberid = this.memberid;
                }

                let params = {
                    contractNumberString: this.contractNumberString,
                    customerString: this.customerString,
                    addressString: this.addressString,
                    startContractDate: this.formatDateToIso(this.startContractDate),
                    endContractDate: this.formatDateToIso(this.endContractDate),
                    startDeadlineDate: this.formatDateToIso(this.startDeadlineDate),
                    endDeadlineDate: this.formatDateToIso(this.endDeadlineDate),
                    status: this.statusArr,
                    favorite: this.favorite,
                    userid: this.$store.state.auth.user.userid,
                    maxResults: this.maxResults,
                    managerid: this.managerid,
                    memberid: memberid
                }



                OrderDataService.find(params)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            //Запрос всех сотрудников для выбора в списке
            getEmployees() {
                EmployeeDataService.find()
                    .then(response => {
                        this.employeesItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            // formatDate (date) {
            //     if (!date) return null
            //
            //     const [year, month, day] = date.split('-')
            //     return `${day}.${month}.${year}`
            // },
            //Печать таблицы
            printTable() {
                let printContent = document.getElementById('printableTable')
                let WinPrint = window.open('','','width=900, height=650')
                WinPrint.document.write(printContent.innerHTML)
                WinPrint.document.close()
                WinPrint.focus()
                WinPrint.print()
                WinPrint.close()
            },
            formatDateToIso(dateString) {
                if (!dateString) return ''
                return [dateString.slice(6,10),dateString.slice(3,5),dateString.slice(0,2)].join('-')
            },
            getColor (orderstatus) {
                if (orderstatus == 0) return 'blue'
                else if (orderstatus == 1) return 'green'
                else if (orderstatus == 3) return 'red'
            },
            getProgressColor (orderdata, orderdeadline) {
                let t1 = new Date(orderdata)
                let t2 = new Date(orderdeadline)
                let procent = this.calculateProcent(t1, t2)

                if (procent >= 0 && procent <= 50) { return 'green' }
                if (procent >= 50 && procent <= 80) { return 'yellow darken-2' }
                if (procent >= 80 && procent <= 100) { return 'red' }
            },

            //Функция вычисляет процент для отображения прогрессбара
            //В функцию передаются объекты даты
            calculateProcent(orderDate, orderDeadlineDate) {
                let t1 = orderDate.getTime()
                let t2 = orderDeadlineDate.getTime()
                let interval = parseInt((t2-t1)/(24*3600*1000)) //date diff inDays
                let value

                if (interval == 0) { interval = 1} //Чтобы не было деление на 0.

                let currDate = new Date()
                let deadline_interval = parseInt((t2 -currDate)/(24*3600*1000)); //Осталось до конца договора (количество дней)

                if (deadline_interval < 0) {
                    value = 100
                } else {
                    value = ((100 / interval) * (interval - deadline_interval))
                }
                return Math.round(value) //времени осталось (%)
            },
        }
    }
</script>

<style scoped>
    .reportwarning {
        /*background-color: #fbaeae;*/
        /*padding: 2px 5px 2px 5px;*/
        /*border-radius: 3px;*/
        color: red;
    }

        /*Уменьшаем отступ в чекбоксе*/
    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 4px;
    }

    .igi {
        color: white;
        background-color: #00b028;
        padding: 1px 3px;
    }

    .bur {
        color: white;
        background-color: #93664a;
        padding: 1px 3px;
    }


</style>