<template>
    <div>
        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Корреспонденция</span>
            </v-col>
            <v-col>
                <AddCorrespondenceForm/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                    <v-text-field
                            label="Номер"
                            placeholder="Введите данные для поиска"
                            v-model="numberString"
                            @keyup.enter="get"
                    ></v-text-field>
                    <v-text-field
                            label="Получатель"
                            placeholder="Введите данные для поиска"
                            v-model="customerString"
                            @keyup.enter="get"
                    ></v-text-field>
                    <v-text-field
                            label="Краткое содержание"
                            placeholder="Введите данные для поиска"
                            v-model="contentsString"
                            @keyup.enter="get"
                    ></v-text-field>
                        <v-text-field
                                label="Договор"
                                placeholder="Введите данные для поиска"
                                v-model="orderString"
                                @keyup.enter="get"
                        ></v-text-field>
                    <v-row>
                        <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                            <v-text-field
                                    label="Дата письма"
                                    placeholder="Начало"
                                    v-model="startDate"
                                    v-mask="'##.##.####'"
                                    :rules="dateRules"
                                    ref="startDate"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                            <v-text-field
                                    placeholder="Окончание"
                                    v-model="endDate"
                                    v-mask="'##.##.####'"
                                    :rules="dateRules"
                                    ref="endDate"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                        <div class="data-label">Вид письма </div>
                     <div v-for="item in viewType" :key="item.id">
                         <v-checkbox
                                 v-model="viewTypeArr"
                                 :value="item.id"
                                 :label="`${item.name}`"
                                 hide-details="true"
                         ></v-checkbox>
                     </div>


                        <v-row
                                align="center"
                                justify="space-around"
                                class="mt-8"
                        >
                            <v-btn
                                    depressed
                                    @click="resetForm"
                            >
                                Сбросить
                            </v-btn>
                            <v-btn
                                    depressed
                                    color="primary"
                                    @click="get"
                            >
                                Применить
                            </v-btn>
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col cols="10">
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                >
                    <template v-slot:item.date.date="{ item }">
                        {{ item.date.date | formatDate}}
                    </template>

                    <template v-slot:item.number="{ item }">
                        <router-link
                                class="itemlink" :to="{ name: 'EditCorrespondence', params: { id: item.id }}">{{ item.number }}
                        </router-link>
                    </template>

                    <template v-slot:item.customercode.fullname="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditCustomer', params: { id: item.customercode.customerid }}">{{ item.customercode.fullname }}</router-link>
                    </template>

                    <template v-slot:item.ordernumber="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditContract', params: { id: item.orderid }}">{{ item.ordernumber }}</router-link>
                    </template>

                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import CorrespondenceDataService from "@/services/CorrespondenceDataService";
    import AddCorrespondenceForm from "@/components/AddCorrespondenceForm";

    export default {
        name: "CorrespondenceTable",
        components: {AddCorrespondenceForm},
        data () {
            return {
                viewType: [],
                numberString: '',
                customerString: '',
                contentsString: '',
                orderString: '',
                loading: true,
                startDate: '',
                endDate: '',
                viewTypeArr: [],
                headers: [
                    {
                        text: 'Номер',
                        value: 'number',
                        width: 90
                    },
                    {
                        text: 'Дата',
                        value: 'date.date',
                        width: 100
                    },
                    {
                        text: 'Тип',
                        value: 'typecode.name',
                        width: 110
                    },
                    {
                        text: 'Получатель',
                        value: 'customercode.fullname',
                        width: 300,
                        sortable: false
                    },
                    {
                        text: 'Исполнитель',
                        value: 'employeecode.initialsemployees',
                        width: 170,
                        sortable: false
                    },
                    {
                        text: 'Договор',
                        value: 'ordernumber',
                        width: 170,
                        sortable: false
                    },
                    {
                        text: 'Краткое содержание',
                        value: 'contents',
                        sortable: false
                    },


                ],
                items: [],
                dateRules: [
                    v => (!v || /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(v)) || 'Пожалуйста, введите дату',
                ],
            }
        },
        mounted () {
            //read Localstorage
            if (localStorage.correspondenceNumberString) {
                this.numberString = localStorage.correspondenceNumberString
            }
            if (localStorage.correspondenceCustomerString) {
                this.customerString = localStorage.correspondenceCustomerString
            }
            if (localStorage.correspondenceContentsString) {
                this.contentsString = localStorage.correspondenceContentsString
            }
            if (localStorage.correspondenceOrderString) {
                this.orderString = localStorage.correspondenceOrderString
            }
            if (localStorage.correspondenceStartDate) {
                this.startDate = localStorage.correspondenceStartDate
            }
            if (localStorage.correspondenceEndDate) {
                this.endDate = localStorage.correspondenceEndDate
            }
            if (localStorage.correspondenceViewTypeArr) {
                this.viewTypeArr = JSON.parse(localStorage.getItem('correspondenceViewTypeArr'))
            }

            //Запрашиваем типы корреспонденции (для поиска)
            this.getCorrespondenceType()
            //Запрашиваем данные с сервера
            this.get()
        },
        filters: {
            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
        },
        methods: {
            resetForm: function () {
                this.numberString = ''
                this.customerString = ''
                this.contentsString = ''
                this.startDate = ''
                this.orderString = ''
                this.endDate = ''
                this.viewTypeArr = []
            },
            get: function () {
                //Save Localstorage
                localStorage.correspondenceNumberString = this.numberString
                localStorage.correspondenceCustomerString = this.customerString
                localStorage.correspondenceContentsString = this.contentsString
                localStorage.correspondenceOrderString = this.orderString
                localStorage.correspondenceStartDate = this.startDate
                localStorage.correspondenceEndDate = this.endDate
                localStorage.correspondenceViewTypeArr = JSON.stringify(this.viewTypeArr)

                this.loading = true

                CorrespondenceDataService.find(this.numberString, this.customerString, this.contentsString, this.orderString, this.formatDateToIso(this.startDate),this.formatDateToIso(this.endDate), this.viewTypeArr)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            getCorrespondenceType() {
                CorrespondenceDataService.getViewtype()
                    .then(response => {
                        this.viewType = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            formatDateToIso(dateString) {
                if (!dateString) return ''
                return [dateString.slice(6,10),dateString.slice(3,5),dateString.slice(0,2)].join('-')
            },
        }
    }
</script>

<style scoped>
    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 4px;
    }
</style>