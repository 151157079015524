<template>
    <div>
        <v-row>
            <v-col>
                <AddCorrespondenceForm :order_id="$route.params.id"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :loading="loading"
                        hide-default-footer
                >
                    <template v-slot:item.date.date="{ item }">
                        {{ item.date.date | formatDate}}
                    </template>

                    <template v-slot:item.number="{ item }">
                        <router-link
                                class="itemlink" :to="{ name: 'EditCorrespondence', params: { id: item.id }}">{{ item.number }}
                        </router-link>
                    </template>

                    <template v-slot:item.customercode.fullname="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditCustomer', params: { id: item.customercode.customerid }}">{{ item.customercode.fullname }}</router-link>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import CorrespondenceDataService from "@/services/CorrespondenceDataService";
    import AddCorrespondenceForm from "@/components/AddCorrespondenceForm";
    export default {
        name: "CorrespondenceTable",
        components: { AddCorrespondenceForm },
        data() {
            return {
                items: [],
                headers: [
                    {
                        text: 'Номер',
                        value: 'number',
                        width: 90
                    },
                    {
                        text: 'Дата',
                        value: 'date.date',
                        width: 100
                    },
                    {
                        text: 'Тип',
                        value: 'typecode.name',
                        width: 110
                    },
                    {
                        text: 'Получатель',
                        value: 'customercode.fullname',
                        width: 300,
                        sortable: false
                    },
                    {
                        text: 'Исполнитель',
                        value: 'employeecode.initialsemployees',
                        width: 170,
                        sortable: false
                    },
                    {
                        text: 'Краткое содержание',
                        value: 'contents',
                        sortable: false
                    },
                    {
                        text: 'Примечания',
                        value: 'description',
                        sortable: false
                    },


                ],
            }
        },
        methods: {
            get(orderid) {
                CorrespondenceDataService.findByOrderId(orderid)
                    .then(response => {
                        this.items = response.data
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
        },
        mounted() {
            this.get(this.$route.params.id); //Код текущего договора
        },
        filters: {
            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
        },
    }
</script>

<style scoped>

</style>