import Vue from 'vue';
import Vuetify from 'vuetify/lib';
//import colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify);

//Загружаем русский язык
import ru from 'vuetify/lib/locale/ru'

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        themes: {
            light: {
                //primary: '#3f51b5',
                primary: '#2a6197',
                // secondary: '#b0bec5',
                // accent: '#8c9eff',
                // error: '#b71c1c',
            },
        },

    },
    //Подключаем русский язык. https://v2.vuetifyjs.com/en/features/internationalization/#getting-started
    lang: {
        locales: { ru },
        current: 'ru',
    },

});
