<template>
    <div>
        <!--Слушаем пользовательское событие в дочернем компоненте-->
        <AddCustomerForm @create-customer-event="handleCreateCustomerEvent"/>

        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Клиенты</span>
            </v-col>
            <v-col class="d-flex justify-end">
<!--                Скрываем кнопку импорт на период разработки-->
                <ImportCustomers class="mr-3 d-none"/>
                <v-btn
                        color="primary"
                        depressed
                        @click="addCustomer"
                >
                    Создать
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                    <v-text-field
                            label="Фамилия ФЛ/ Наименование ЮЛ"
                            placeholder="Введите данные для поиска"
                            v-model="searchString"
                            @keyup.enter="get"
                            hint="Несколько слов разделять пробелом"
                    ></v-text-field>
                        <v-text-field
                                label="ИНН"
                                placeholder="Введите данные для поиска"
                                v-model="innString"
                                @keyup.enter="get"
                                hint="Точное совпадение"
                                :rules="externalRules.integerRules"
                        ></v-text-field>
                        <v-row
                                align="center"
                                justify="space-around"
                                class="mt-5"
                        >
                            <v-btn
                                    depressed
                                    @click="resetForm"
                                    class="mb-3"
                            >
                                Сбросить
                            </v-btn>
                            <v-btn
                                    depressed
                                    color="primary"
                                    @click="get"
                                    class="mb-3"
                            >
                                Применить
                            </v-btn>
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col cols="10">
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                >
                    <template v-slot:item.clienttype="{ item }">
                        {{ item.clienttype | formatClientType}}
                    </template>
                    <template v-slot:item.fullname="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditCustomer', params: { id: item.customerid }}">{{ item.fullname }}</router-link>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import CustomerDataService from "@/services/CustomerDataService";
    import AddCustomerForm from "@/components/AddCustomerForm";
    import ImportCustomers from "@/components/Customers/ImportCustomers";
    import * as ExternalRules from "@/rules/ExternalRules";

    export default {
        name: "CustomersTable",
        components: {AddCustomerForm, ImportCustomers},

        data () {
            return {
                searchString: '', //Строка поиска - ФИО/Наименование
                innString: '', //Строка поиска - ИНН
                loading: true,
                externalRules: ExternalRules,
                headers: [
                    {
                        text: 'ФИО / Наименование',
                        value: 'fullname',
                        width: 500
                    },
                    {
                        text: 'Тип клиента',
                        value: 'clienttype',
                        width: 300
                    },
                    {
                        text: 'ИНН',
                        value: 'inn',
                        width: 150
                    },
                    {
                        text: 'Примечания',
                        value: 'description',
                        sortable: false
                    },


                ],
                items: [],
            }
        },
        mounted () {
            //read Localstorage
            if (localStorage.customerssearchstring) {
                this.searchString = localStorage.customerssearchstring
            }
            if (localStorage.customersinnstring) {
                this.innString = localStorage.customersinnstring
            }

            //Запрашиваем данные с сервера
            this.get()
        },
        filters: {
            formatClientType: function (clienttype) {
                switch (clienttype) {
                    case 'CL' :
                       return 'Клиент'
                    case 'PD' :
                        return 'Подрядчик'
                    case 'KF' :
                        return 'Контактное лицо'
                    case 'OU' :
                        return 'Эксплуатирующая организация'
                    default:
                        return clienttype
                }
            }
        },
        methods: {
            addCustomer() {
                this.$store.dispatch('showAddCustomerDialog');
            },
            //При срабатывании события создания клиента в дочернем компоненте - передаправляем на страницу редактирования
            handleCreateCustomerEvent(customerid) {
                this.$router.push({ name: 'EditCustomer', params: { id : customerid, showSendPassMessage: true }}) //Передаем параметр компоненту - Показать диалог отправки пароля от ЛК
            },
            resetForm: function () {
                this.searchString = ''
                this.innString = ''
            },
            get: function () {
                //Save Localstorage
                localStorage.customerssearchstring = this.searchString
                localStorage.customersinnstring = this.innString

                this.loading = true
                CustomerDataService.findBySurname(this.searchString,'','',this.innString)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        }
    }
</script>

<style scoped>

</style>