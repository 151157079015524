<template>
  <div>

      <v-btn
        class="mb-10"
        color="red"
      >
          Добавить образец
      </v-btn>


      <v-btn @click="checkAbility">
          Проверить абилити
      </v-btn>
      <div>
          {{AbilityObj}} <br/><br/>
          {{AbilityFlag}}
      </div>

      <table>
          <thead>
          <tr>
              <th style="writing-mode: vertical-lr">Номер скважины</th>
              <th>Глубина отбора, м</th>
              <th>ИГЭ</th>
              <th>Тип грунта</th>
              <th>W</th>
              <th>%dasfdasfd</th>
          </tr>
          </thead>
          <tbody>
              <tr v-for="(row, index) in rows" :key="index">
                  <td>
                      <input
                              v-model="row.number"
                              type="text"
                      />
                  </td>
                  <td>
                      <input v-model="row.depth"/>
                  </td>
                  <td>
                      <input v-model="row.depth"/>
                  </td>
                  <td>
                      <input v-model="row.depth"/>
                  </td>
                  <td>
                      <input v-model="row.depth"/>
                  </td>
                  <td>
                    {{row.depth + row.depth }}
                  </td>
<!--                  <td>-->

<!--                      <select>-->
<!--                          <option value="iphone 6s"></option>-->
<!--                          <option value="lumia 950">Да</option>-->
<!--                          <option value="nexus 5x">Нет</option>-->
<!--                      </select>-->

<!--                  </td>-->
              </tr>
          </tbody>
          <tfoot>
<!--          <tr>-->
<!--              <th colspan="2">Average age</th>-->
<!--              <td>33</td>-->
<!--          </tr>-->
          </tfoot>
      </table>

<br/>

      <table>
          <thead>
          <tr>
              <th>Глубина</th>
              <th>Лоб, МПа</th>
              <th>Бок, КПа</th>
              <th>Rf, %</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in staticData" :key="index">
              <td>
                  <input
                          v-model="row.depth"
                          @keypress="validateInput"
                          @input="formatInput"
                  />
              </td>
              <td>
                  <input
                          v-model="row.lob"
                          @keypress="validateInput"
                          @input="formatInput"
                  />
              </td>
              <td>
                  <input
                          v-model="row.bok"
                          @keypress="validateInput"
                          @input="formatInput"
                  />
              </td>
              <td>
<!--                  Переводим килопакали в мегапаскали-->
                  {{ ((row.bok / (row.lob*1000)) * 100).toFixed(2) }}
              </td>
          </tr>
          </tbody>
      </table>

<br/><br/><br/>


      <v-card>
          <v-toolbar
                  flat
                  color="primary"
                  dark
          >
              <v-toolbar-title>Новый образец</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical>
              <v-tab class="left-align">
                  <v-icon left>
                      mdi-account
                  </v-icon>
                  Паспорт
              </v-tab>
              <v-tab class="left-align">
                  <v-icon left>
                      mdi-lock
                  </v-icon>
                  Влажность
              </v-tab >
              <v-tab class="left-align">
                  <v-icon left>
                      mdi-access-point
                  </v-icon>
                  Плотность
              </v-tab>
              <v-tab class="left-align">
                  <v-icon left>
                      mdi-access-point
                  </v-icon>
                  Грансостав
              </v-tab>
              <v-tab class="left-align">
                  <v-icon left>
                      mdi-access-point
                  </v-icon>
                  Заторфованность
              </v-tab>
              <v-tab class="left-align">
                  <v-icon left>
                      mdi-access-point
                  </v-icon>
                  Фильтрация
              </v-tab>
              <v-tab class="left-align">
                  <v-icon left>
                      mdi-access-point
                  </v-icon>
                  Компрессия
              </v-tab>
              <v-tab class="left-align">
                  <v-icon left>
                      mdi-access-point
                  </v-icon>
                  Срез
              </v-tab>
              <v-tab class="left-align">
                  <v-icon left>
                      mdi-access-point
                  </v-icon>
                  3-осное сжатие
              </v-tab>
              <v-tab class="left-align">
                  <v-icon left>
                      mdi-access-point
                  </v-icon>
                  1-осное сжатие
              </v-tab>
              <v-tab class="left-align">
                  <v-icon left>
                      mdi-access-point
                  </v-icon>
                  Плотность мерзлого грунта
              </v-tab>

              <v-tab-item>
                  <v-card flat>
                      <v-card-text>
                          <v-expansion-panels multiple>
                              <v-expansion-panel
                                      v-for="(item,i) in 5"
                                      :key="i"
                              >
                                  <v-expansion-panel-header>
                                      Образец
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>

                                      <v-container>
                                          <v-row>
                                              <v-col>
                                                  <v-text-field
                                                      label="Скважина"
                                                        hide-details
                                                  ></v-text-field>
                                                  <v-text-field
                                                          label="Глубина"
                                                          hide-details
                                                  ></v-text-field>
                                                  <v-text-field
                                                          label="Номер образца"
                                                          hide-details
                                                  ></v-text-field>
                                              </v-col>
                                          </v-row>
                                      </v-container>


                                  </v-expansion-panel-content>
                              </v-expansion-panel>
                          </v-expansion-panels>
                      </v-card-text>
                  </v-card>
              </v-tab-item>
              <v-tab-item>
                  <v-card flat>
                      <v-card-text>

                      </v-card-text>
                  </v-card>
              </v-tab-item>
              <v-tab-item>
                  <v-card flat>
                      <v-card-text>

                      </v-card-text>
                  </v-card>
              </v-tab-item>
          </v-tabs>
      </v-card>


      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>







  </div>
</template>

<script>
    import store from "@/store";

    const ability = store.getters['auth/ability']

export default {
  name: 'IGI',
    data () {
        return {

            rows: [
                {number: 1, depth: 2.5, description: "hello"},
                {number: 2, depth: 2.55, description: "hello2"},
                {number: 3, depth: 5.5, description: "hello3"},
                {number: 4, depth: 21.5, description: "hello4"}
            ],

            staticData: [
                {number: 1, lob: 0.05, bok: 1},
                {number: 2, lob: 2.55, bok: 3},
                {number: 3, lob: 5.5, bok: 4},
                {number: 4, lob: 21.5, bok: 5}
            ],


            isEditing: [],

            AbilityFlag: null,
            AbilityObj: null,
            numberInput: ''

        }
    },

    methods: {

      checkAbility () {
          this.AbilityObj =  ability
              //this.AbilityFlag =  ability.rules  //Только правила
              //this.AbilityFlag = ability.can('ORDER_DELETE',  {  "owner": true, "sort": "2144"})
          this.AbilityFlag = ability.can('ORDERS_LIST_READ',  {  "owner": true})
      },

        validateInput(event) {
            const char = String.fromCharCode(event.which);
            // Проверяем, является ли введенный символ цифрой или точкой
            if (!/[0-9.]/.test(char)) {
                event.preventDefault(); // Отменяем ввод, если символ не подходит
            }
        },
        formatInput() {
            // Удаляем все символы, кроме цифр и точки
            this.number = this.number.replace(/[^0-9.]/g, '');
        }




    }

}
</script>

<style scoped>
    table {
        border-collapse: collapse;
        border: 2px solid rgb(140 140 140);
        font-family: sans-serif;
        font-size: 0.8rem;
        background-color: white;
    }


    thead, tfoot {
        background-color: rgb(228 240 245);
    }

    th, td {
        border: 1px solid rgb(160 160 160);
        padding: 5px 6px;
    }

    td:last-of-type {
        text-align: center;
    }

    tbody > tr:nth-of-type(even) {
        background-color: rgb(237 238 242);
    }

    tfoot th {
        text-align: right;
    }

    tfoot td {
        font-weight: bold;
    }

    input {
        width: 100%;
        box-sizing: border-box;
        /*Убираем границы инпута при фокусе*/
        outline: none;
    }



    .left-align {
        justify-content: flex-start; /* Выравнивание по левому краю */
        display: flex; /* Обеспечивает использование flexbox */
    }

</style>
