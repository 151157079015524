var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AddEmployeeForm',{on:{"updateEmploeesTable":_vm.get}}),_c('v-row',[_c('v-col',[_c('span',{staticStyle:{"font-size":"25px","font-weight":"300"}},[_vm._v("Сотрудники")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.addEmployee}},[_vm._v(" Создать ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"ФИО","placeholder":"Введите данные для поиска","hint":"Несколько слов разделять пробелом"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.get($event)}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),(_vm.VUE_APP_TENANT !== 'GEOREESTR')?_c('v-row',[_c('v-col',{staticStyle:{"margin-bottom":"50px","padding-top":"0","margin-top":"15px"}},[_c('div',{staticClass:"data-label"},[_vm._v("Дополнительные фильтры")]),_c('v-checkbox',{staticStyle:{"margin-top":"3px"},attrs:{"label":"Работает","hide-details":""},model:{value:(_vm.isWorks),callback:function ($$v) {_vm.isWorks=$$v},expression:"isWorks"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{staticClass:"mb-3",attrs:{"depressed":""},on:{"click":_vm.resetForm}},[_vm._v(" Сбросить ")]),_c('v-btn',{staticClass:"mb-3",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.get}},[_vm._v(" Применить ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":50,"footer-props":{
                         itemsPerPageOptions: [ 50, 100, -1],
                         itemsPerPageAllText: 'Все',
                         itemsPerPageText: 'Строк на странице:'
                    },"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
                    var item = ref.item;
return [_c('v-avatar',{staticClass:"float-left",attrs:{"size":"35"}},[_c('img',{attrs:{"src":("" + _vm.avatars + (item.avatar))}})])]}},{key:"item.fullname",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{staticClass:"itemlink",attrs:{"to":{ name: 'EditEmployee', params: { id: item.employeesid }}}},[_vm._v(_vm._s(item.fullname))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }