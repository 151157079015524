export default {
    state: {
        isAddTaskDialogOpen: false
    },
    actions: {
        showAddTaskDialog({ commit }) {
            commit('OPEN_ADD_TASK_DIALOG');
        },
        closeAddTaskDialog({ commit }) {
            commit('CLOSE_ADD_TASK_DIALOG');
        }
    },
    mutations: {
        OPEN_ADD_TASK_DIALOG(state) {
            state.isAddTaskDialogOpen = true
        },
        CLOSE_ADD_TASK_DIALOG(state) {
            state.isAddTaskDialogOpen = false
        }
    },

    getters: {
        isAddTaskDialogOpen: state => {
            return state.isAddTaskDialogOpen
        },
    }
}