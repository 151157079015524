<template>
  <div>

      <!--Диалоговое окно создания клиента-->
      <AddCustomerForm @create-customer-event="handleCreateCustomerEvent" :allow_create_ul="false" pfClienttype="KF"/>

      <!--Сообщение об ошибке сохранения в БД-->
      <v-alert
              type="error"
              dismissible
              v-model="alertError"
              transition="scale-transition"
      >
          Невозможно сохранить данные. Возможно отсутствует соединение с базой данных. Обратитесь к Администратору.
      </v-alert>


      <!--Сообщение об успешном копировании данных-->

          <v-snackbar
                  v-model="snackbarCopy"
                  color="green darken-3"
                  top
                  :timeout="1000"
          >
             Скопировано!
              <template v-slot:action="{ attrs }">
                  <v-btn
                          color="grey lighten-2"
                          icon
                          v-bind="attrs"
                          @click="snackbarCopy = false"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </template>
          </v-snackbar>

      <!--Сообщение об успешном Сохранении данных-->
      <v-snackbar
              v-model="snackbarSave"
              color="green"
              top
              :timeout="1000"
      >
          Данные сохранены
          <template v-slot:action="{ attrs }">
              <v-btn
                      color="grey lighten-2"
                      icon
                      v-bind="attrs"
                      @click="snackbarSave = false"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>

      <!--Сообщение об успешной отправке e-mail или sms с рег данными ЛК-->
      <v-snackbar
              v-model="snackbarSendLogin"
              color="green"
              top
              :timeout="4000"
      >
          Логин и новый пароль успешно отправлены
          <template v-slot:action="{ attrs }">
              <v-btn
                      color="grey lighten-2"
                      icon
                      v-bind="attrs"
                      @click="snackbarSendLogin = false"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>

      <!--Сообщение об ОШИБКЕ отправке e-mail или sms с рег данными ЛК-->
      <v-snackbar
              v-model="snackbarErrorSendLogin"
              color="red"
              top
              :timeout="4000"
      >
          Ошибка отправки логина и пароля
          <template v-slot:action="{ attrs }">
              <v-btn
                      color="grey lighten-2"
                      icon
                      v-bind="attrs"
                      @click="snackbarErrorSendLogin = false"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>

<!--      ДИАЛОГОВЫЕ ОКНА РЕДАКТИРОВАНИЯ-->

      <!--      Диалоговое окно привязки компании к ФЛ (контакту)-->
      <v-dialog
              max-width="500"
              persistent
              v-model="bindCompanyDialog"
      >
          <v-card>
              <v-card-title class="headline">
                  Привязать компанию
                  <v-spacer></v-spacer>
                  <v-btn
                          icon
                          @click="closeBindCompanyDialog"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col>
                              <v-autocomplete
                                        placeholder="Введите не менее 3-х символов для поиска"
                                        clearable
                                        v-model="company"
                                        :items="companyItems"
                                        :search-input.sync="search"
                                        return-object
                                        item-text="fullname"
                                        ref="company"
                                        label="Компания"
                                        :rules="externalRules.requiredRules"
                              ></v-autocomplete>
                              <v-text-field
                                      v-model="editedItem.post"
                                      label="Должность"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="closeBindCompanyDialog"
                  >
                      Отмена
                  </v-btn>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="saveBindCompany"
                  >
                      Добавить
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <!--      Диалоговое окно привязки контактного лица к компании-->
      <v-dialog
              max-width="500"
              persistent
              v-model="addContactDialog"
      >
          <v-card>
              <v-card-title class="headline">
                  Добавить контакт
                  <v-spacer></v-spacer>
                  <v-btn
                          icon
                          @click="closeAddContactDialog"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col>
                              <v-autocomplete
                                      placeholder="Введите не менее 3-х символов для поиска"
                                      clearable
                                      v-model="company"
                                      :items="companyItems"
                                      :search-input.sync="searchContact"
                                      return-object
                                      item-text="fullname"
                                      ref="contact"
                                      label="Контактное лицо"
                                      :rules="externalRules.requiredRules"
                                      append-outer-icon="mdi-pencil-plus-outline"
                                      @click:append-outer="addCustomer"
                              ></v-autocomplete>
                              <v-text-field
                                      v-model="editedItem.post"
                                      label="Должность"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="closeAddContactDialog"
                  >
                      Отмена
                  </v-btn>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="saveAddContact"
                  >
                      Добавить
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

    <!--      Диалоговое окно генерации и отправки пароля ЛК -->
      <LKPasswordGenerateAndSend v-model="showLKPasswordGenerateAndSendDialog" :customerid="customer.customerid"/>

      <!--      Диалоговое окно запроса отправки пароля для ЛК -->
      <v-dialog v-model="dialogSendPassLk" max-width="500px">
          <v-card>
              <v-card-title>Отправить клиенту логин и пароль для ЛК?</v-card-title>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialogSendPassLk">Отмена</v-btn>
                  <v-btn color="blue darken-1" text @click="ShowSendPassLKDialog">Отправить</v-btn>
                  <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog>

<!--      Диалоговое окно редактирования ФИО ФЛ-->
      <v-dialog
              max-width="500"
              persistent
              v-model="dialog"
      >
          <v-card>
              <v-card-title class="headline">
                  Редактирование
                  <v-spacer></v-spacer>
                  <v-btn
                          icon
                          @click="close"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col>
                              <v-form
                                      ref="flform"
                                      v-model="valid"
                                      lazy-validation
                              >
                                  <v-text-field
                                          v-model="editedItem.surname"
                                          label="Фамилия*"
                                          :rules="requiredRules"
                                          required
                                  ></v-text-field>
                                  <v-text-field
                                          v-model="editedItem.name"
                                          label="Имя*"
                                          :rules="requiredRules"
                                          required
                                  ></v-text-field>
                                  <v-text-field
                                          v-model="editedItem.secondname"
                                          label="Отчество"
                                  ></v-text-field>
                              </v-form>
                          </v-col>
                      </v-row>
                  </v-container>
                  <small>* поля обязательны для заполнения</small>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                  >
                      Отмена
                  </v-btn>
                  <v-btn
                          :disabled="!valid"
                          color="blue darken-1"
                          text
                          @click="save"
                  >
                      Сохранить
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <!--      Диалоговое окно редактирования Наименования ЮЛ-->
      <v-dialog
              max-width="500"
              persistent
              v-model="FlNameDialog"
      >
          <v-card>
              <v-card-title class="headline">
                  Редактирование
                  <v-spacer></v-spacer>
                  <v-btn
                          icon
                          @click="closeFlNameDialog"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.surname"
                                      label="Наименование*"
                                      :rules="requiredRules"
                                      ref="surname"
                                      required
                              ></v-text-field>
                          </v-col>
                      </v-row>
                  </v-container>
                  <small>* поля обязательны для заполнения</small>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="closeFlNameDialog"
                  >
                      Отмена
                  </v-btn>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="saveFlNameDialog"
                  >
                      Сохранить
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <!--      Диалоговое окно редактирования пасспортных данных-->
      <v-dialog
              max-width="600"
              persistent
              v-model="passportDataDialog"
      >
          <v-card>
              <v-card-title class="headline">
                  Редактирование
                  <v-spacer></v-spacer>
                  <v-btn
                          icon
                          @click="closePassportDataDialog"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.passportseries"
                                      label="Серия"
                                      v-mask="'## ##'"
                              ></v-text-field>
                          </v-col>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.passportnumber"
                                      label="Номер"
                                      v-mask="'######'"
                              ></v-text-field>
                          </v-col>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.passportdata"
                                      label="Дата выдачи"
                                      :rules="dateRules"
                                      v-mask="'##.##.####'"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.passportgivenout"
                                      label="Кем выдан"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="closePassportDataDialog"
                  >
                      Отмена
                  </v-btn>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="savePassportDataDialog "
                  >
                      Сохранить
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>


      <!--      Диалоговое окно редактирования банковских реквизитов-->
      <v-dialog
              max-width="600"
              persistent
              v-model="bankDataDialog"
      >
          <v-card>
              <v-card-title class="headline">
                  Редактирование
                  <v-spacer></v-spacer>
                  <v-btn
                          icon
                          @click="closeBankDataDialog"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.settlementaccount"
                                      label="Расчетный счет"

                              ></v-text-field>
                          </v-col>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.correspondentaccount"
                                      label="Корреспондентский счет"

                              ></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.bankname"
                                      label="Наименование банка"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.bik"
                                      label="БИК"

                              ></v-text-field>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="closeBankDataDialog"
                  >
                      Отмена
                  </v-btn>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="saveBankDataDialog "
                  >
                      Сохранить
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <!-- Диалоговое окно редактирования адреса ФЛ-->
      <v-dialog
              max-width="600"
              persistent
              v-model="addressDialog"
      >
          <v-card>
              <v-card-title class="headline">
                  Редактирование
                  <v-spacer></v-spacer>
                  <v-btn
                          icon
                          @click="closeAddressDialog"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.adressregregion"
                                      label="Регион"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.adressregdistrict"
                                      label="Район"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.adressregsettlementpoint"
                                      label="Населенный пункт"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.adressregstreet"
                                      label="Улица"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.adressreghouse"
                                      label="Дом"
                              ></v-text-field>
                          </v-col>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.adressregcorps"
                                      label="Корпус"
                              ></v-text-field>
                          </v-col>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.adressregbuild"
                                      label="Строение"
                              ></v-text-field>
                          </v-col>
                          <v-col>
                              <v-text-field
                                      v-model="editedItem.adressregflat"
                                      label="Квартира"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="closeAddressDialog"
                  >
                      Отмена
                  </v-btn>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="saveAddressDialog"
                  >
                      Сохранить
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <!--Физическое лицо-->
      <div v-if="customer.customertype === `P`">
          <v-row>
              <v-col
                      cols="auto"
              >
                  <h1 class="data-name">
                    {{ fullName }}
                          <v-icon
                                  @click="editItem"
                                  right
                          >
                              mdi-pencil
                          </v-icon>
                          <v-icon
                                  @click="copy(fullName)"
                                  right
                          >
                              mdi-content-copy
                          </v-icon>
                  </h1>
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                      cols="auto"
              >
                  <v-select
                          style="width: 300px"
                          :items="clientTypes"
                          item-text="text"
                          item-value="value"
                          persistent-hint
                          label="Тип клиента"
                          v-model="customer.clienttype"
                          @change="updateCustomer"
                  ></v-select>
              </v-col>

              <v-col
                      cols="auto"
              >
                  <v-switch
                          label="Активен"
                          v-model="customer.enabled"
                          @change="updateCustomer"
                  ></v-switch>
              </v-col>
          </v-row>
          <v-row>
              <v-col>
                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Контактные данные</v-card-title>
                      <v-card-text>

<!--                        <v-row class="mx-0">-->
<!--                          <div class="data-row">-->
<!--                            <div class="data-label">-->
<!--                              E-mail-->
<!--                            </div>-->
<!--                            <div class="data-text" v-show="!emailInPlace">-->
<!--                                <span v-if="customer.email"> {{ customer.email }} </span>-->
<!--                                <span class="placeholder" v-else>Введите адрес электронной почты</span>-->
<!--                                <v-icon-->
<!--                                      @click="editEmail"-->
<!--                                      right-->
<!--                                      small-->
<!--                              >-->
<!--                                mdi-pencil-->
<!--                              </v-icon>-->

<!--                                <v-tooltip bottom>-->
<!--                                    <template v-slot:activator="{ on, attrs }">-->
<!--                                        <v-icon-->
<!--                                                v-show="customer.email"-->
<!--                                                @click="sendLoginToEmail(customer.customerid, customer.email)"-->
<!--                                                right-->
<!--                                                small-->
<!--                                                v-bind="attrs"-->
<!--                                                v-on="on"-->
<!--                                        >-->
<!--                                            mdi-form-textbox-password-->
<!--                                        </v-icon>-->
<!--                                    </template>-->
<!--                                    <span>Отправить логин и пароль на почту</span>-->
<!--                                </v-tooltip>-->
<!--                              <v-icon-->
<!--                                      v-show="customer.email"-->
<!--                                      @click="copy(customer.email)"-->
<!--                                      right-->
<!--                                      small-->
<!--                              >-->
<!--                                mdi-content-copy-->
<!--                              </v-icon>-->
<!--                            </div>-->
<!--                              <div v-show="emailInPlace">-->
<!--                                  <v-form-->
<!--                                          ref="flemail"-->
<!--                                          v-model="valid"-->
<!--                                          lazy-validation-->
<!--                                  >-->
<!--                                      <v-text-field-->
<!--                                              v-model="editedItem.email"-->
<!--                                              type="email"-->
<!--                                              :rules="emailRules"-->
<!--                                              hint="Можно ввести несколько e-mail: example@mail.ru,example2@mail.ru"-->
<!--                                              ref="email"-->
<!--                                      ></v-text-field>-->
<!--                                  </v-form>-->
<!--                                  <v-btn-->
<!--                                          color="blue darken-1"-->
<!--                                          text-->
<!--                                          @click="closeEmailInPlace"-->
<!--                                  >-->
<!--                                      Отмена-->
<!--                                  </v-btn>-->
<!--                                  <v-btn-->
<!--                                          :disabled="!valid"-->
<!--                                          color="blue darken-1"-->
<!--                                          text-->
<!--                                          @click="saveEmailInPlace"-->
<!--                                  >-->
<!--                                      Сохранить-->
<!--                                  </v-btn>-->
<!--                              </div>-->

<!--                          </div>-->
<!--                        </v-row>-->
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      E-mail
                                  </div>
                                  <Emails/>
                              </div>
                          </v-row>
                        <v-row class="mx-0">
                          <div class="data-row">
                            <div class="data-label">
                              Телефоны
                            </div>
                              <Phones/>
                          </div>
                        </v-row>
                      </v-card-text>
                  </v-card>

                  <v-card
                          outlined
                          class="mb-4"
                          v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                  >
                      <v-card-title>Личный кабинет</v-card-title>
                      <v-card-text>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Логин
                                  </div>
                                  <div class="data-text">
                                      {{ customer.login }}
                                  </div>

                                  <v-btn
                                    small
                                    depressed
                                    outlined
                                    color="primary"
                                    class="mt-5"
                                    @click="passwordGenLK"
                                  >
                                      Отправить пароль
                                  </v-btn>

                              </div>
                          </v-row>
                      </v-card-text>
                  </v-card>

                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Примечания</v-card-title>
                      <v-card-text>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div
                                          class="data-text"
                                          v-show="!descriptionInPlace"
                                  >
                                      <span v-if="customer.description"> {{ customer.description }} </span>
                                      <span class="placeholder" v-else>Введите текст</span>
                                      <v-icon
                                              @click="editDescriptionInPlace"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="customer.description"
                                              @click="copy(customer.description)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>



                                  <div v-show="descriptionInPlace">
                                      <v-textarea
                                              v-model="editedItem.description"
                                              ref="description"
                                      ></v-textarea>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeDescriptionInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveDescriptionInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>



                              </div>
                          </v-row>
                      </v-card-text>
                  </v-card>
              </v-col>
              <v-col>

                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Дополнительная информация</v-card-title>

                      <v-card-text>
                        <v-row class="mx-0">
                          <div class="data-row">
                            <div class="data-label">
                                Дата рождения
                            </div>
                            <div class="data-text" v-show="!birthdayInPlace">
                                <span v-if="customer.birthday"> {{ customer.birthday }} </span>
                                <span class="placeholder" v-else>Введите дату</span>
                                <v-icon
                                        @click="editBirthday"
                                        right
                                        small
                                >
                                  mdi-pencil
                                </v-icon>
                                <v-icon
                                        v-show="customer.birthday"
                                        @click="copy(customer.birthday)"
                                        right
                                        small
                                >
                                  mdi-content-copy
                                </v-icon>
                            </div>
                              <div v-show="birthdayInPlace">
                                  <v-form
                                          ref="birthday"
                                          v-model="valid"
                                          lazy-validation
                                  >
                                      <v-text-field
                                              v-model="editedItem.birthday"
                                              :rules="dateRules"
                                              v-mask="'##.##.####'"
                                              ref="birthday"
                                      ></v-text-field>
                                  </v-form>
                                  <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="closeBirthdayInPlace"
                                  >
                                      Отмена
                                  </v-btn>
                                  <v-btn
                                          :disabled="!valid"
                                          color="blue darken-1"
                                          text
                                          @click="saveBirthdayInPlace"
                                  >
                                      Сохранить
                                  </v-btn>
                              </div>
                          </div>

                        </v-row>

                        <v-row class="mx-0">
                          <div class="data-row">
                                  <div class="data-label">
                                      СНИЛС
                                  </div>
                                  <div class="data-text" v-show="!snilsInPlace">
                                      <span v-if="customer.snils"> {{ customer.snils }} </span>
                                      <span class="placeholder" v-else>Введите номер</span>
                                      <v-icon
                                              @click="editSnils"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="customer.snils"
                                              @click="copy(customer.snils)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="snilsInPlace">
                                      <v-text-field
                                              v-model="editedItem.snils"
                                              v-mask="'###-###-### ##'"
                                              ref="snils"
                                      ></v-text-field>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeSnilsInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveSnilsInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                          </div>
                        </v-row>

                        <v-row class="mx-0">
                          <div class="data-row">
                            <div class="data-label">
                              Паспортные данные
                            </div>
                            <div class="data-text">
                                <span v-if="passport"> {{ passport }} </span>
                                <span class="placeholder" v-else>Введите данные</span>
                              <v-icon
                                      @click="editPassportData"
                                      right
                                      small
                              >
                                mdi-pencil
                              </v-icon>
                              <v-icon
                                      v-show="passport"
                                      @click="copy(passport)"
                                      right
                                      small
                              >
                                mdi-content-copy
                              </v-icon>
                            </div>
                          </div>
                        </v-row>

                        <v-row class="mx-0">
                          <div class="data-row">
                            <div class="data-label">
                              Адрес регистрации
                            </div>
                            <div class="data-text">
                                <span v-if="fullAddress"> {{ fullAddress }} </span>
                                <span class="placeholder" v-else>Введите данные</span>
                              <v-icon
                                      @click="editAddress"
                                      right
                                      small
                              >
                                mdi-pencil
                              </v-icon>
                              <v-icon
                                      v-show="fullAddress"
                                      @click="copy(fullAddress)"
                                      right
                                      small
                              >
                                mdi-content-copy
                              </v-icon>
                            </div>
                          </div>
                        </v-row>

                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Компания
                                  </div>

                                  <div class="data-text" style="margin-bottom: 10px" v-for="item in companyNameItems" :key="item.company.customerid">
                                      <span style=" padding-right: 5px; ">{{ item.company.fullname }}</span>
                                      <span style="color: #716f71; padding-right: 5px; font-size: 13px">{{ item.post }}</span>
                                      <v-icon
                                              @click="clearCompanyBind(item.company.customerid)"
                                              right
                                              small
                                              title="Открепить компанию"
                                      >
                                          mdi-close-circle-outline
                                      </v-icon>
                                      <v-icon
                                              @click="goToCompany(item.company.customerid)"
                                              right
                                              small
                                              title="Перейти к компании"
                                      >
                                          mdi-arrow-top-right
                                      </v-icon>

                                  </div>
                                  <v-btn
                                          x-small
                                          rounded
                                          color="primary"
                                          depressed
                                          @click="bindCompany"
                                  >
                                      Добавить
                                  </v-btn>

                              </div>

                          </v-row>


<!--                        <v-row class="mx-0">-->
<!--                          <div class="data-row">-->
<!--                              <div class="data-label">-->
<!--                                  Должность-->
<!--                              </div>-->
<!--                              <div class="data-text" v-show="!postInPlace">-->
<!--                                  <span v-if="customer.post"> {{ customer.post }} </span>-->
<!--                                  <span class="placeholder" v-else>Введите текст</span>-->
<!--                                  <v-icon-->
<!--                                          @click="editPost"-->
<!--                                          right-->
<!--                                          small-->
<!--                                  >-->
<!--                                      mdi-pencil-->
<!--                                  </v-icon>-->
<!--                                  <v-icon-->
<!--                                          v-show="customer.post"-->
<!--                                          @click="copy(customer.post)"-->
<!--                                          right-->
<!--                                          small-->
<!--                                  >-->
<!--                                      mdi-content-copy-->
<!--                                  </v-icon>-->
<!--                              </div>-->
<!--                              <div v-show="postInPlace">-->
<!--                                  <v-text-field-->
<!--                                          v-model="editedItem.post"-->
<!--                                          ref="post"-->
<!--                                  ></v-text-field>-->
<!--                                  <v-btn-->
<!--                                          color="blue darken-1"-->
<!--                                          text-->
<!--                                          @click="closePostInPlace"-->
<!--                                  >-->
<!--                                      Отмена-->
<!--                                  </v-btn>-->
<!--                                  <v-btn-->
<!--                                          color="blue darken-1"-->
<!--                                          text-->
<!--                                          @click="savePostInPlace"-->
<!--                                  >-->
<!--                                      Сохранить-->
<!--                                  </v-btn>-->
<!--                              </div>-->
<!--                          </div>-->
<!--                        </v-row>-->

                      </v-card-text>
                  </v-card>

                  <!--Компонент События. Времено скрываем  -->
                <Event :sourcecode="customer.customerid" class="d-none"/>

              </v-col>
          </v-row>
      </div>

      <!--Юридическое лицо-->
      <div v-if="customer.customertype === `L`">
          <v-row>
              <v-col
                      cols="auto"
              >
                  <h1 class="data-name">
                      {{ fullName }}
                      <v-icon
                              @click="editFLName"
                              right
                      >
                          mdi-pencil
                      </v-icon>
                      <v-icon
                              @click="copy(fullName)"
                              right
                      >
                          mdi-content-copy
                      </v-icon>
                  </h1>
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                      cols="auto"
              >
                  <v-select
                          style="width: 300px"
                          :items="clientUlTypes"
                          item-text="text"
                          item-value="value"
                          persistent-hint
                          label="Тип клиента"
                          v-model="customer.clienttype"
                          @change="updateCustomer"
                  ></v-select>
              </v-col>

              <v-col
                      cols="auto"
              >
                  <v-switch
                          label="Активен"
                          v-model="customer.enabled"
                          @change="updateCustomer"
                  ></v-switch>
              </v-col>
          </v-row>
          <v-row>
              <v-col>
                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Контактные данные</v-card-title>
                      <v-card-text>

<!--                          <v-row class="mx-0">-->
<!--                              <div class="data-row">-->
<!--                                  <div class="data-label">-->
<!--                                      E-mail-->
<!--                                  </div>-->
<!--                                  <div class="data-text" v-show="!emailInPlace">-->
<!--                                      <span v-if="customer.email"> {{ customer.email }} </span>-->
<!--                                      <span class="placeholder" v-else>Введите адрес электронной почты</span>-->
<!--                                      <v-icon-->
<!--                                              @click="editEmail"-->
<!--                                              right-->
<!--                                              small-->
<!--                                      >-->
<!--                                          mdi-pencil-->
<!--                                      </v-icon>-->
<!--                                      <v-tooltip bottom>-->
<!--                                          <template v-slot:activator="{ on, attrs }">-->
<!--                                              <v-icon-->
<!--                                                      v-show="customer.email"-->
<!--                                                      @click="sendLoginToEmail(customer.customerid, customer.email)"-->
<!--                                                      right-->
<!--                                                      small-->
<!--                                                      v-bind="attrs"-->
<!--                                                      v-on="on"-->
<!--                                              >-->
<!--                                                  mdi-form-textbox-password-->
<!--                                              </v-icon>-->
<!--                                          </template>-->
<!--                                          <span>Отправить логин и пароль на почту</span>-->
<!--                                      </v-tooltip>-->
<!--                                      <v-icon-->
<!--                                              v-show="customer.email"-->
<!--                                              @click="copy(customer.email)"-->
<!--                                              right-->
<!--                                              small-->
<!--                                      >-->
<!--                                          mdi-content-copy-->
<!--                                      </v-icon>-->
<!--                                  </div>-->
<!--                                  <div v-show="emailInPlace">-->
<!--                                      <v-form-->
<!--                                              ref="flemail"-->
<!--                                              v-model="valid"-->
<!--                                              lazy-validation-->
<!--                                      >-->
<!--                                          <v-text-field-->
<!--                                                  v-model="editedItem.email"-->
<!--                                                  type="email"-->
<!--                                                  :rules="emailRules"-->
<!--                                                  hint="Можно ввести несколько e-mail: example@mail.ru,example2@mail.ru"-->
<!--                                                  ref="email"-->
<!--                                          ></v-text-field>-->
<!--                                      </v-form>-->
<!--                                      <v-btn-->
<!--                                              color="blue darken-1"-->
<!--                                              text-->
<!--                                              @click="closeEmailInPlace"-->
<!--                                      >-->
<!--                                          Отмена-->
<!--                                      </v-btn>-->
<!--                                      <v-btn-->
<!--                                              :disabled="!valid"-->
<!--                                              color="blue darken-1"-->
<!--                                              text-->
<!--                                              @click="saveEmailInPlace"-->
<!--                                      >-->
<!--                                          Сохранить-->
<!--                                      </v-btn>-->
<!--                                  </div>-->

<!--                              </div>-->
<!--                          </v-row>-->
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      E-mail
                                  </div>
<!--                                  Вводим паремерт KEY для обновления компонента при изменении URL (при переходе к контактному лицу)-->
                                  <Emails :key="$route.fullPath"/>
                              </div>
                          </v-row>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Телефоны
                                  </div>
<!--                                  Вводим паремерт KEY для обновления компонента при изменении URL (при переходе к контактному лицу)-->
                                  <Phones :key="$route.fullPath"/>
                              </div>
                          </v-row>
                      </v-card-text>
                  </v-card>

<!--                  Скрываем виджет с паролем, в связи с новым функционалом - ЮЛ не могут входить в ЛК - только контактные лица -->
                  <v-card
                          outlined
                          class="mb-4"
                          v-show="false"
                  >
                      <v-card-title>Личный кабинет</v-card-title>
                      <v-card-text>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Логин
                                  </div>
                                  <div class="data-text">
                                      {{ customer.login }}
                                  </div>

                                  <v-btn
                                          small
                                          depressed
                                          outlined
                                          color="primary"
                                          class="mt-5"
                                          @click="passwordGenLK"
                                  >
                                      Отправить пароль
                                  </v-btn>

                              </div>
                          </v-row>
                      </v-card-text>
                  </v-card>

                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Примечания</v-card-title>
                      <v-card-text>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div
                                          class="data-text"
                                          v-show="!descriptionInPlace"
                                  >
                                      <span v-if="customer.description"> {{ customer.description }} </span>
                                      <span class="placeholder" v-else>Введите текст</span>
                                      <v-icon
                                              @click="editDescriptionInPlace"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="customer.description"
                                              @click="copy(customer.description)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>



                                  <div v-show="descriptionInPlace">
                                      <v-textarea
                                              v-model="editedItem.description"
                                              ref="description"
                                      ></v-textarea>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeDescriptionInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveDescriptionInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>



                              </div>
                          </v-row>
                      </v-card-text>
                  </v-card>

              </v-col>
              <v-col>
                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Контактные лица</v-card-title>
                      <v-card-text>
                          <div class="d-flex flex-wrap justify-start">
                              <div
                                      v-for="item in contactFaces"
                                      :key="item.contact.customerid"
                                      class="contact-container mr-10 outer"
                              >
                                  <v-avatar
                                          size="50"
                                          class="float-left"
                                          color="blue"
                                          @click="goToContactFace(item.contact.customerid)"
                                          style="cursor:pointer"
                                  >
                                      <span class="white--text text-h6">{{item.contact.surname.slice(0,1)}}{{item.contact.name.slice(0,1)}}</span>
                                  </v-avatar>
                                  <div class="avatar-text" style="float: left">
                                      <p class="fio">{{ item.contact.fullname }}</p>
                                      <p class="post">{{ item.post }}</p>
                                  </div>
                                <v-icon
                                        class="float-right"
                                        @click="deleteContactBind(item.contact.customerid)"
                                        style="color: rgb(13, 71, 161);"
                                        size="20"
                                        title="Удалить"
                                >
                                    mdi-trash-can-outline
                                </v-icon>
                              </div>
                              <div>
                                    <v-icon
                                            style="color:#b0b0b0"
                                            size="40"
                                            class="mt-2"
                                            @click="addContact"
                                    >
                                        mdi-account-plus-outline
                                    </v-icon>
                              </div>
                          </div>

<!--                          <v-btn-->
<!--                          @click="openTestModal">-->
<!--                              Открыть диалог через VUEX-->
<!--                          </v-btn>-->



                      </v-card-text>
                  </v-card>

                      <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Дополнительная информация</v-card-title>

                      <v-card-text>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      ИНН
                                  </div>
                                  <div class="data-text" v-show="!innInPlace">
                                      <span v-if="customer.inn"> {{ customer.inn }} </span>
                                      <span class="placeholder" v-else>Введите номер</span>
                                      <v-icon
                                              @click="editInn"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="customer.inn"
                                              @click="copy(customer.inn)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="innInPlace">
                                      <v-form
                                              ref="inn"
                                              v-model="valid"
                                              lazy-validation
                                      >
                                          <v-text-field
                                                  v-model="editedItem.inn"
                                                  :rules="requiredRules"
                                                  v-mask="'#############'"
                                                  ref="inn"
                                          ></v-text-field>
                                      </v-form>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeInnInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              :disabled="!valid"
                                              color="blue darken-1"
                                              text
                                              @click="saveInnInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>

                          </v-row>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      ОГРН
                                  </div>
                                  <div class="data-text" v-show="!ogrnInPlace">
                                      <span v-if="customer.ogrn"> {{ customer.ogrn }} </span>
                                      <span class="placeholder" v-else>Введите номер</span>
                                      <v-icon
                                              @click="editOgrn"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="customer.ogrn"
                                              @click="copy(customer.ogrn)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="ogrnInPlace">
                                      <v-text-field
                                              v-model="editedItem.ogrn"
                                              v-mask="'#############'"
                                              ref="ogrn"
                                      ></v-text-field>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeOgrnInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveOgrnInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>

                          </v-row>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      КПП
                                  </div>
                                  <div class="data-text" v-show="!kppInPlace">
                                      <span v-if="customer.kpp"> {{ customer.kpp }} </span>
                                      <span class="placeholder" v-else>Введите номер</span>
                                      <v-icon
                                              @click="editKpp"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="customer.kpp"
                                              @click="copy(customer.kpp)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="kppInPlace">
                                      <v-text-field
                                              v-model="editedItem.kpp"
                                              v-mask="'#########'"
                                              ref="kpp"
                                      ></v-text-field>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeKppInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveKppInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>
                          </v-row>

                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Банковские реквизиты
                                  </div>
                                  <div class="data-text">
                                      <span v-if="bank"> {{ bank }} </span>
                                      <span class="placeholder" v-else>Введите данные</span>
                                      <v-icon
                                              @click="editBankData"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="bank"
                                              @click="copy(bank)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                              </div>
                          </v-row>

                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Адрес регистрации
                                  </div>
                                  <div class="data-text">
                                      <span v-if="fullAddress"> {{ fullAddress }} </span>
                                      <span class="placeholder" v-else>Введите данные</span>
                                      <v-icon
                                              @click="editAddress"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="fullAddress"
                                              @click="copy(fullAddress)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                              </div>
                          </v-row>


                      </v-card-text>
                  </v-card>

                  <!--Компонент События  Времено скрываем -->
                  <Event :sourcecode="customer.customerid" class="d-none"/>
              </v-col>
          </v-row>
      </div>
  </div>
</template>
<script>
    import CustomerDataService from "@/services/CustomerDataService";
    import Event from "@/components/Event";
    import Phones from "@/components/Phones";
    import Emails from "@/components/Emails";
    import LKPasswordGenerateAndSend from "@/components/Customers/LKPasswordGenerateAndSend";
    import ContactFacesDataService from "@/services/ContactFacesDataService";
    import AddCustomerForm from "@/components/AddCustomerForm";
    import * as ExternalRules from "@/rules/ExternalRules";

    export default {
        name: 'EditCustomer',
      components: {AddCustomerForm, Phones, Event, Emails, LKPasswordGenerateAndSend},
        props: {
            //Если входной параметр = TRUE - показываем диалоговое окно отправки логина/пароля от ЛК (передаем этот входной параметр из компонента создания КЛИЕНТА)
            showSendPassMessage: {
                type: Boolean,
                default: false
            }
        },
      data: function() {
            return {
                //Редактирование на месте
                descriptionInPlace: false,
                emailInPlace: false,
                birthdayInPlace: false,
                snilsInPlace: false,
                postInPlace: false,
                innInPlace: false,
                ogrnInPlace: false,
                kppInPlace: false,
                companyInPlace: false,
                showLKPasswordGenerateAndSendDialog:false,

                externalRules: ExternalRules,

                //Редактирование в Диалоговом окне
                passportDataDialog: false,
                bankDataDialog: false,
                addressDialog: false,
                dialog: false,
                FlNameDialog: false,
                alertError: false,


                valid: true,

                customer: {},//Объект текущего клиента
                clientTypes: [
                    { text: 'Клиент', value: 'CL' },
                    { text: 'Подрядчик', value: 'PD' },
                    { text: 'Контактное лицо', value: 'KF' },
                ],
                clientUlTypes: [
                    { text: 'Клиент', value: 'CL' },
                    { text: 'Подрядчик', value: 'PD' },
                    { text: 'Эксплуатирующая организация', value: 'OU' },
                ],
                requiredRules: [
                    v => !!v || 'Пожалуйста, заполните это поле',
                ],
                phoneRules: [
                    v => (!v || v.length === 18) || 'Пожалуйста, введите телефон',
                ],
                emailRules: [
                    v => (!v || /.+@.+\..+/.test(v)) || 'Пожалуйста, введите адрес электронной почты',
                ],
                dateRules: [
                    v => (!v || /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(v)) || 'Пожалуйста, введите дату',
                ],

                snackbarCopy: false,
                snackbarSave: false,
                snackbarSendLogin: false,
                snackbarErrorSendLogin: false,

                editedItem: {}, //В этой переменной сохраняем объект редактирования

                //Company
                company: {},
                companyItems: [],
                search: null,
                searchContact: null,
                companyNameItems: [], //Компании, связанные с контактом
                //Контактные лица
                contactFaces: null,
                bindCompanyDialog: false,
                addContactDialog: false,
                dialogSendPassLk: false, //Диалог отравки пароля для ЛК
                VUE_APP_TENANT: process.env.VUE_APP_TENANT
            }
        },
        methods: {
            openTestModal() {
              //this.$store.dispatch('showDialog')
                this.$router.push({ name: 'TestDialog' });
            },

            //Открываем диалог добавления клиента
            addCustomer() {
                this.$store.dispatch('showAddCustomerDialog');
            },

            //При срабатывании события создания клиента в дочернем компоненте
            handleCreateCustomerEvent(customerid) {
                CustomerDataService.get(customerid)
                    .then(response => {
                        this.companyItems = response.data
                        this.company= response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            //Привязыка компании к контакту
            bindCompany() {
                this.bindCompanyDialog = true
                this.editedItem.post = ''
            },
            //ДОбавить контакт
            addContact() {
                this.addContactDialog = true
                this.editedItem.post = ''
            },

            // //Отправка логина на E-mail
            // sendLoginToEmail(customerid,email) {
            //     CustomerDataService.sendLoginToEmail(customerid, email)
            //         .then(response => {
            //             console.log(response.data)
            //             this.snackbarSendLogin = true
            //         })
            //         .catch(e => {
            //             this.snackbarErrorSendLogin = true
            //             console.log(e)
            //         })
            // },
            //Закрываем форму редактирования ФИО без сохранения
            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeEmailInPlace () {
                this.emailInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeBirthdayInPlace () {
                this.birthdayInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeSnilsInPlace () {
                this.snilsInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeOgrnInPlace () {
                this.ogrnInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closePostInPlace () {
                this.postInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeBindCompanyDialog() {
                this.getCompanyNameItems(this.$route.params.id)
                this.bindCompanyDialog = false
                this.$nextTick(() => {
                    this.editedItem = {}
                    //this.editedIndex = -1
                })
            },
            closeAddContactDialog() {
                this.getContactsFace(this.$route.params.id)
                this.addContactDialog = false
                this.$nextTick(() => {
                    this.editedItem = {}
                    //this.editedIndex = -1
                })
            },
            closeInnInPlace () {
                this.innInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeKppInPlace () {
                this.kppInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeDescriptionInPlace () {
                this.descriptionInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeCompanyInPlace() {
                this.companyInPlace = false
                this.company = {}
            },
            closeFlNameDialog () {
                this.FlNameDialog = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closePassportDataDialog () {
                this.passportDataDialog = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeBankDataDialog () {
                this.bankDataDialog = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeAddressDialog () {
                this.addressDialog = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            clearCompanyBind (companyid) {
                ContactFacesDataService.clearBind(companyid, this.customer.customerid)
                    .then(response => {
                        this.getCompanyNameItems(this.$route.params.id)
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            deleteContactBind (contactid) {
                ContactFacesDataService.clearBind(this.customer.customerid, contactid)
                    .then(response => {
                        this.getContactsFace(this.$route.params.id)
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            saveEmailInPlace () {
                if (this.$refs.flemail.validate()) {
                    this.customer.email = this.editedItem.email
                    this.closeEmailInPlace()
                    this.updateCustomer()
                }
            },
            saveBirthdayInPlace () {
                if (this.$refs.birthday.validate()) {
                    this.customer.birthday = this.editedItem.birthday
                    this.closeBirthdayInPlace()
                    this.updateCustomer()
                }
            },
            saveSnilsInPlace () {
                this.customer.snils = this.editedItem.snils
                this.closeSnilsInPlace()
                this.updateCustomer()
            },
            savePostInPlace () {
                this.customer.post = this.editedItem.post
                this.closePostInPlace()
                this.updateCustomer()
            },
            saveInnInPlace () {
                if (this.$refs.inn.validate()) {
                    this.customer.inn = this.editedItem.inn
                    this.closeInnInPlace()
                    this.updateCustomer()
                }
            },
            saveOgrnInPlace () {
                this.customer.ogrn = this.editedItem.ogrn
                this.closeOgrnInPlace()
                this.updateCustomer()
            },
            saveKppInPlace () {
                this.customer.kpp = this.editedItem.kpp
                this.closeKppInPlace()
                this.updateCustomer()
            },
            saveDescriptionInPlace () {
                this.customer.description = this.editedItem.description
                this.closeDescriptionInPlace()
                this.updateCustomer()
            },
            //Создаенм новую запись - привязку компании
            saveBindCompany () {
                ContactFacesDataService.bindcompany(this.company.customerid, this.customer.customerid,this.editedItem.post)
                    .then(response => {
                        this.closeBindCompanyDialog()
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            //Создаенм новую запись - привязку контакта
            saveAddContact () {
                ContactFacesDataService.bindcompany(this.customer.customerid, this.company.customerid, this.editedItem.post)
                    .then(response => {
                        this.closeAddContactDialog()
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            savePassportDataDialog () {
                this.customer.passportseries = this.editedItem.passportseries
                this.customer.passportnumber = this.editedItem.passportnumber
                this.customer.passportdata = this.editedItem.passportdata
                this.customer.passportgivenout = this.editedItem.passportgivenout
                this.closePassportDataDialog()
                this.updateCustomer()
            },
            saveBankDataDialog () {
                this.customer.settlementaccount = this.editedItem.settlementaccount
                this.customer.bankname = this.editedItem.bankname
                this.customer.correspondentaccount = this.editedItem.correspondentaccount
                this.customer.bik = this.editedItem.bik
                this.closeBankDataDialog()
                this.updateCustomer()
            },
            saveAddressDialog () {
                this.customer.adressregregion = this.editedItem.adressregregion
                this.customer.adressregdistrict = this.editedItem.adressregdistrict
                this.customer.adressregsettlementpoint = this.editedItem.adressregsettlementpoint
                this.customer.adressregstreet = this.editedItem.adressregstreet
                this.customer.adressreghouse = this.editedItem.adressreghouse
                this.customer.adressregcorps = this.editedItem.adressregcorps
                this.customer.adressregbuild = this.editedItem.adressregbuild
                this.customer.adressregflat = this.editedItem.adressregflat
                this.closeAddressDialog()
                this.updateCustomer()
            },
            //Сохряняем ФИО и закрываем форму редактирования
            save () {
                if (this.$refs.flform.validate()) {
                    this.customer.name = this.editedItem.name
                    this.customer.secondname = this.editedItem.secondname
                    this.customer.surname = this.editedItem.surname
                    this.close()
                    this.updateCustomer()
                }
            },
            saveFlNameDialog () {
                this.customer.surname = this.editedItem.surname
                this.closeFlNameDialog()
                this.updateCustomer()
            },

            //Открываем форму редактирования ФИО ФЛ
            editItem () {
                this.editedItem.name = this.customer.name
                this.editedItem.surname = this.customer.surname
                this.editedItem.secondname = this.customer.secondname
                this.dialog = true
            },
            //Открываем форму редактирования Наименования ЮЛ
            editFLName () {
                this.editedItem.surname = this.customer.surname
                this.FlNameDialog = true
                this.$nextTick(() => {
                    this.$refs.FlNameDialog.surname.focus();
                })
            },
            //Открываем форму редактирования паспортных данных
            editPassportData() {
                this.editedItem.passportseries = this.customer.passportseries
                this.editedItem.passportnumber = this.customer.passportnumber
                this.editedItem.passportdata = this.customer.passportdata
                this.editedItem.passportgivenout = this.customer.passportgivenout
                this.passportDataDialog = true
            },
            //Открываем форму редактирования банковских реквизитов
            editBankData() {
                this.editedItem.settlementaccount = this.customer.settlementaccount
                this.editedItem.bankname = this.customer.bankname
                this.editedItem.correspondentaccount = this.customer.correspondentaccount
                this.editedItem.bik = this.customer.bik
                this.bankDataDialog = true
            },
            //Открываем форму редактирования адреса ФЛ
            editAddress() {
                this.editedItem.adressregregion = this.customer.adressregregion
                this.editedItem.adressregdistrict = this.customer.adressregdistrict
                this.editedItem.adressregsettlementpoint = this.customer.adressregsettlementpoint
                this.editedItem.adressregstreet = this.customer.adressregstreet
                this.editedItem.adressreghouse = this.customer.adressreghouse
                this.editedItem.adressregcorps = this.customer.adressregcorps
                this.editedItem.adressregbuild = this.customer.adressregbuild
                this.editedItem.adressregflat = this.customer.adressregflat
                this.addressDialog = true
            },
            editEmail() {
                this.editedItem.email = this.customer.email
                this.emailInPlace = true
                this.$nextTick(() => {
                    this.$refs.email.focus();
                })
            },
            //Открываем форму генерации и отправки пароля ЛК
            passwordGenLK() {
                this.showLKPasswordGenerateAndSendDialog = true
            },
            editBirthday() {
                this.editedItem.birthday = this.customer.birthday
                this.birthdayInPlace = true
                this.$nextTick(() => {
                    this.$refs.birthday.focus();
                })
            },
            editSnils() {
                this.editedItem.snils = this.customer.snils
                this.snilsInPlace = true
                this.$nextTick(() => {
                    this.$refs.snils.focus();
                })
            },
            editCompany() {
                this.companyInPlace = true
                this.$nextTick(() => {
                    this.$refs.company.focus();
                })
            },
            editInn() {
                this.editedItem.inn = this.customer.inn
                this.innInPlace = true
                this.$nextTick(() => {
                    this.$refs.inn.focus();
                })
            },
            editOgrn() {
                this.editedItem.ogrn = this.customer.ogrn
                this.ogrnInPlace = true
                this.$nextTick(() => {
                    this.$refs.ogrn.focus();
                })
            },
            editKpp() {
                this.editedItem.kpp = this.customer.kpp
                this.kppInPlace = true
                this.$nextTick(() => {
                    this.$refs.kpp.focus();
                })
            },
            editPost() {
                this.editedItem.post = this.customer.post
                this.postInPlace = true
                this.$nextTick(() => {
                    this.$refs.post.focus();
                })
            },
            editDescriptionInPlace() {
                this.editedItem.description = this.customer.description
                this.descriptionInPlace = true
                this.$nextTick(() => {
                    this.$refs.description.focus();
                })
            },
            goToCompany(id) {
                this.$router.push({name: 'EditCustomer', params: { id: id }})
            },
            goToContactFace(id) {
                this.$router.push({name: 'EditCustomer', params: { id: id }})
            },
            copy (text) {
                this.$copyText(text).then(response => {
                    this.snackbarCopy = true
                    console.log(response)
                })
                .catch(error => {
                    console.log(error);
                })
            },
            getCustomer(id) {
                CustomerDataService.get(id)
                    .then(response => {
                        this.customer = response.data
                        console.log(response.data);
                        //Временно отключаем так как не использую. Переосмыслить
                        // if (this.showSendPassMessage == true && this.customer.customertype == 'P') { //Если новый клиент и он Физическое лицо - показываем запрос на отправку логина от ЛК. Для ЮЛ пароль не отправляем, потомучто он не может зайти в ЛК
                        //     this.dialogSendPassLk = true
                        // }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            getCompanyNameItems(id) {
                ContactFacesDataService.findCompanies(id)
                    .then(response => {
                        this.companyNameItems = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            getContactsFace(id) {
                // CustomerDataService.findBySurname('', '', id)
                //     .then(response => {
                //         this.contactFaces = response.data
                //         console.log(response.data)
                //     })
                //     .catch(e => {
                //         console.log(e)
                //     })

                ContactFacesDataService.findContacts(id)
                    .then(response => {
                        this.contactFaces = response.data
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })

            },
            updateCustomer() {
                CustomerDataService.update(this.customer.customerid, this.customer)
                    .then(response => {
                        this.snackbarSave = true
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                        this.alertError = true
                    })
            },
            closeDialogSendPassLk() {
                this.dialogSendPassLk = false
            },
            ShowSendPassLKDialog() {
                this.passwordGenLK()
                this.dialogSendPassLk = false
            }
        },


        async mounted()
    {
        await this.getCustomer(this.$route.params.id); //Код текущего клиента
        await this.getContactsFace(this.$route.params.id) //Контактные лица для компании
        await this.getCompanyNameItems(this.$route.params.id)
        //if (this.showSendPassMessage == true) {
            //this.passwordGenLK()
        //}

    },


        beforeUpdate() {
            //this.getCompanyNameItems(this.customer.customerid) //Связанные компании для физического лица //Почемуто циклически вызывает сетвевое событие
        },
        //При переходе к этому же компоненту (Компания)
        beforeRouteUpdate(to, from, next) {
            this.getCustomer(to.params.id);
            this.getContactsFace(to.params.id)
            this.getCompanyNameItems(to.params.id)
            next()
        },
        watch: {
            search() {
                if (this.search.length > 3) {
                    CustomerDataService.findBySurname(this.search, 'L')
                        .then(response => {
                            this.companyItems = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
            searchContact() {
                if (this.searchContact.length > 3) {
                    CustomerDataService.findBySurname(this.searchContact, 'P')
                        .then(response => {
                            this.companyItems = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            }
        },
        computed: {
            //ФИО
            fullName() {
                return [this.customer.surname, this.customer.name, this.customer.secondname].join(' ')
            },
            //Паспортные данные
            passport() {
                let passportseries = (this.customer.passportseries) ? `серия ${this.customer.passportseries} ` : null
                let passportnumber = (this.customer.passportnumber) ? `номер ${this.customer.passportnumber} ` : null
                let passportgivenout = (this.customer.passportgivenout) ? `выдан ${this.customer.passportgivenout} ` : null
                let passportdata = (this.customer.passportdata) ? `${this.customer.passportdata} ` : null

                return [passportseries, passportnumber, passportgivenout, passportdata].join('')
            },
            //Полный адрес
            fullAddress() {
                let adressregregion = (this.customer.adressregregion) ? `${this.customer.adressregregion}, ` : null
                let adressregdistrict = (this.customer.adressregdistrict) ? `${this.customer.adressregdistrict}, ` : null
                let adressregsettlementpoint = (this.customer.adressregsettlementpoint) ? `${this.customer.adressregsettlementpoint}, ` : null
                let adressregstreet = (this.customer.adressregstreet) ? `${this.customer.adressregstreet}, ` : null
                let adressreghouse = (this.customer.adressreghouse) ? `д.${this.customer.adressreghouse}, ` : null
                let adressregcorps = (this.customer.adressregcorps) ? `корп.${this.customer.adressregcorps}, ` : null
                let adressregbuild = (this.customer.adressregbuild) ? `стр.${this.customer.adressregbuild}, ` : null
                let adressregflat = (this.customer.adressregflat) ? `кв.${this.customer.adressregflat}, ` : null

                return [adressregregion, adressregdistrict, adressregsettlementpoint, adressregstreet, adressreghouse, adressregcorps, adressregbuild, adressregflat].join('')
            },
            //Бпнковские реквизиты
            bank() {
                let settlementaccount = (this.customer.settlementaccount) ? `Расчетный счет ${this.customer.settlementaccount} ` : null
                let bankname = (this.customer.bankname) ? `в ${this.customer.bankname}\n` : null
                let correspondentaccount = (this.customer.correspondentaccount) ? `Корреспондентский счет ${this.customer.correspondentaccount}\n` : null
                let bik = (this.customer.bik) ? `БИК ${this.customer.bik}` : null

                return [settlementaccount, bankname, correspondentaccount, bik].join('')
            },
        }
    }
</script>
<style scoped>
    .data-name .v-icon:hover {
      color: #0d47a1;
    }

    .contact-container {
        min-height: 90px;
    }

    .avatar-text {
        padding-left: 10px;
        max-width: 150px;
    }

    .outer .v-icon {
        visibility: hidden;
    }

    .outer:hover .v-icon {
        visibility: visible;
    }

</style>