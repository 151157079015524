export default {
    state: {
        isAddContractDialogOpen: false
    },
    actions: {
        showAddContractDialog({ commit }) {
            commit('OPEN_ADD_CONTRACT_DIALOG');
        },
        closeAddContractDialog({ commit }) {
            commit('CLOSE_ADD_CONTRACT_DIALOG');
        }
    },
    mutations: {
        OPEN_ADD_CONTRACT_DIALOG(state) {
            state.isAddContractDialogOpen = true
        },
        CLOSE_ADD_CONTRACT_DIALOG(state) {
            state.isAddContractDialogOpen = false
        }
    },

    getters: {
        isAddContractDialogOpen: state => {
            return state.isAddContractDialogOpen
        },
    }
}