var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AddTaskForm',{attrs:{"order_id":_vm.$route.params.id},on:{"updateTasks":_vm.get}}),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.addTask}},[_vm._v(" Создать ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"itemlink",class:{done : item.status == 1},attrs:{"to":{ name: 'EditTask', params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.importance == 1)?_c('v-icon',{attrs:{"color":"red","size":"20"}},[_vm._v(" mdi-fire ")]):_vm._e()]}},{key:"item.end_date.date",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{enddeadline: _vm.getDeadlineStatus(item.end_date.date, item.status) == true}},[_vm._v(" "+_vm._s(_vm.formatDeadlineDate (item.end_date.date, item.status ))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('task-stepper',{attrs:{"statusid":item.status}})]}},{key:"item.create_employee_name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"margin-right":"5px"},attrs:{"size":"30"}},[_c('img',{attrs:{"src":("" + _vm.avatars + (item.create_employee_avatar))}})]),_vm._v(" "+_vm._s(item.create_employee_name)+" ")]}},{key:"item.responsible_employee_name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"margin-right":"5px"},attrs:{"size":"30"}},[_c('img',{attrs:{"src":("" + _vm.avatars + (item.responsible_employee_avatar))}})]),_vm._v(" "+_vm._s(item.responsible_employee_name)+" ")]}},{key:"item.create_date.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.create_date.date))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }