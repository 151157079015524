<template>
    <div class="text-right">
        <v-dialog
                v-model="isModalOpen"
                persistent
                max-width="600"
                hide-overlay
        >
            <v-card>
                <v-card-title class="headline">
                    Новая задача
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form
                        ref="form"
                        v-model="validForm"
                        lazy-validation
                >
                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col style="margin-top: 0; padding-top: 0">
                                <v-checkbox
                                        v-model="importance"
                                        label="Это горящая задача"
                                        hide-details="true"
                                        color="red"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="name"
                                        label="Название задачи*"
                                        :rules="externalRules.requiredRules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                        v-model="description"
                                        label="Описание"
                                        rows="2"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        label="Ответственный*"
                                        :rules="externalRules.requiredRules"
                                        v-model="employeeId"
                                        :items="employeeItems"
                                        item-value="employeesid"
                                        item-text="fullname"
                                        clearable
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-avatar>
                                            <img :src="`${avatars}${data.item.avatar}`">
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.post"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <vc-date-picker v-model="endDate" :model-config="modelConfig">
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <v-text-field
                                                label="Крайний срок"
                                                :value="inputValue"
                                                :rules="externalRules.dateRules"
                                                @click="togglePopover()"
                                                v-mask="'##.##.####'"
                                                clearable
                                                v-model="endDate"
                                        />
                                    </template>
                                </vc-date-picker>
                            </v-col>
                        </v-row>
                        <!--                        Не показываем это поле, если вызываем диалог из договора.-->
                        <!--                        В дальнейшем исправить это.-->
                        <v-row v-show="!order_id">
                            <v-col>
                                <v-autocomplete
                                        placeholder="Введите не менее 3-х символов для поиска"
                                        clearable
                                        v-model="orderid"
                                        :items="orderItems"
                                        :search-input.sync="orderSearch"
                                        item-value="orderid"
                                        item-text="contractnumber"
                                        label="Договор"
                                        @click:clear="clearOrderSearch"
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.contractnumber"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.estatecod.fulladdress"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>


                        <v-row>
                            <v-col>
                                <v-checkbox
                                        label="Редактировать задачу"
                                        v-model="editTask"
                                >
                                </v-checkbox>
                                <v-btn
                                        class="mr-4 mt-4"
                                        color="success"
                                        :loading="loading"
                                        @click="submitForm"
                                >
                                    Создать
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>
                </v-form>

            </v-card>



        </v-dialog>
    </div>
</template>

<script>
    import OrderDataService from "@/services/OrderDataService";
    import * as ExternalRules from "@/rules/ExternalRules";
    import EmployeeDataService from "@/services/EmployeeDataService";
    import TaskDataService from "@/services/TaskDataService";
    import NoticeDataService from "@/services/NoticeDataService";


    // let options = {
    //     year: 'numeric',
    //     month: 'numeric',
    //     day: 'numeric',
    //     timezone: 'UTC'
    // };

    export default {
        name: "AddTaskForm",
        props: {
            order_id: {
                type: Number,
                default: null
            }
        },
        data () {
            return {
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    // mask: 'YYYY-MM-DD',
                    mask: 'DD.MM.YYYY',
                },
                importance: 0,
                name: null,
                description: null,
                //date: new Date().toISOString().split('T')[0], //Дата в формате YYYY-MM-DD
                employeeItems: [],
                employeeId: null,
                orderid: this.order_id,
                //date: new Date().toLocaleString("ru", options),
                endDate: null, //Крайний срок
                orderItems: [],
                externalRules: ExternalRules,
                publicPath: process.env.BASE_URL,
                avatars: process.env.VUE_APP_AVATARS,
                dialog: false,
                validForm: true,
                orderSearch: null,
                loading: false,
                editTask: true
            }
        },

        watch: {
            orderSearch() {
                if (this.orderItems.length > 0) return

                if (this.orderSearch.length >= 3) {
                    let params = {
                        contractNumberString: this.orderSearch,
                        maxResults: 50
                    }
                    OrderDataService.find(params)
                        .then(response => {
                            this.orderItems = response.data
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
            dialog(dialog) {
                //Если открываем диалог - обнуляем переменные
                if(dialog === true) {
                    this.loading = false
                    this.name = null
                    this.description = null
                    this.orderSearch = null
                    this.validForm = true
                    this.orderItems = []
                    this.endDate = null
                    this.employeeId = null
                    this.importance = 0
                    this.editTask = true
                }
            }

        },
        computed: {
            isModalOpen() {
                return this.$store.getters.isAddTaskDialogOpen;
            },
        },
        methods: {
            closeDialog() {
                this.$store.dispatch('closeAddTaskDialog');
            },

            submitForm() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    let data = {
                        name: this.name,
                        description: this.description,
                        status: 0,
                        importance: this.importance,
                        enddate: this.endDate ? this.endDate.split(".").reverse().join("-") : null, //Преобразуем в дату ISO формата
                        employeeid: this.employeeId,
                        orderid: this.orderid,
                        creatorid: this.$store.state.auth.user.userid,   //Создатель задачи
                        startdate: new Date().toISOString().split('T')[0], //Дата в формате YYYY-MM-DD //Дата создания задачи
                        createtime: new Date().toISOString().split('T')[1], //Время создания задачи
                    }

                    TaskDataService.create(data)
                        .then(response => {
                            if (data.creatorid !== data.employeeid) { //Если поставлена задача самому себе - уведомление не создаем
                                NoticeDataService.create({creatorid: data.creatorid, employeesid: data.employeeid, noticetext: `Вам назначена новая задача: <a href="/tasks/${response.data.id}">${this.name}</a><br>Срок выполнения: ${this.endDate ? this.endDate : " не установлено"}`});//Создаем уведомление
                            }
                            //
                            if (this.editTask === true) {
                                this.$router.push({ name: 'EditTask', params: { id : response.data.id }})
                            } else {
                                this.$emit('updateTasks') //Генерируем событие - Обновить лист задач
                                this.closeDialog() // Закрываем диалог
                            }

                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            },

            clearCustomerSearch() {
                this.customerItems = []
            },
            clearOrderSearch() {
                this.orderItems = []
            },

            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },

            getEmployees() {
                EmployeeDataService.find()
                    .then(response => {
                        this.employeeItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
        },
        mounted () {
            this.getEmployees() //Сотрудники
        }
    }
</script>

<style scoped>


</style>