export default {
    state: {
        isAddEmployeeDialogOpen: false
    },
    actions: {
        showAddEmployeeDialog({ commit }) {
            commit('OPEN_ADD_EMPLOYEE_DIALOG');
        },
        closeAddEmployeeDialog({ commit }) {
            commit('CLOSE_ADD_EMPLOYEE_DIALOG');
        }
    },
    mutations: {
        OPEN_ADD_EMPLOYEE_DIALOG(state) {
            state.isAddEmployeeDialogOpen = true
        },
        CLOSE_ADD_EMPLOYEE_DIALOG(state) {
            state.isAddEmployeeDialogOpen = false
        }
    },

    getters: {
        isAddEmployeeDialogOpen: state => {
            return state.isAddEmployeeDialogOpen
        },
    }
}