<template>
    <div class="text-right">
        <v-dialog
                v-model="isModalOpen"
                persistent
                width="600"
        >

            <v-card>
                <v-card-title class="headline">
                    Новый сотрудник
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form
                        ref="form"
                        v-model="validForm"
                        lazy-validation
                >
                    <v-card-text>
                    <v-container>

                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="surname"
                                        label="Фамилия"
                                        :rules="externalRules.requiredRules"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                            >
                                <v-text-field
                                        v-model="name"
                                        label="Имя"
                                        :rules="externalRules.requiredRules"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                            >
                                <v-text-field
                                        v-model="secondname"
                                        label="Отчество"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                        v-model="gender"
                                        :items="genderSelect"
                                        label="Пол"
                                        item-text="label"
                                        item-value="value"
                                        :rules="externalRules.requiredRules"
                                ></v-select>
                                <v-input>

                                </v-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="password"
                                        type="password"
                                        label="Пароль"
                                        :rules="externalRules.requiredRules.concat(externalRules.passwordRules)"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        v-model="passwordConfirm"
                                        type="password"
                                        label="Подтверждение"
                                        :rules="externalRules.requiredRules.concat(passwordConfirmationRule)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <vc-date-picker v-model="birthday" :model-config="modelConfig">
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <v-text-field
                                                label="Дата рождения"
                                                :value="inputValue"
                                                :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                                @click="togglePopover()"
                                                v-mask="'##.##.####'"
                                                clearable
                                                v-model="birthday"
                                        />
                                    </template>
                                </vc-date-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="post"
                                        label="Должность"
                                        :rules="externalRules.requiredRules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="phone"
                                        label="Телефон"
                                        :rules="externalRules.requiredRules.concat(externalRules.phoneRules)"
                                        v-mask="'+7 (###) ###-##-##'"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="email"
                                        label="E-mail"
                                        :rules="externalRules.requiredRules.concat(externalRules.emailRules)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        label="Роли"
                                        v-model="roleIds"
                                        :items="roleItems"
                                        item-value="id"
                                        item-text="name"
                                        chips
                                        deletable-chips
                                        multiple
                                        :rules="externalRules.requiredRules"
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>

                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn
                                        class="mr-3 mt-4"
                                        color="primary"
                                        :loading="loading"
                                        :disabled="!validForm"
                                        @click="submitForm"
                                >
                                    Создать
                                </v-btn>
<!--                                Скрываем, считаю пока не нужен такой функционал-->
<!--                                <v-btn-->
<!--                                        class="mr-4 mt-4"-->
<!--                                        color="primary"-->
<!--                                        :loading="loading"-->
<!--                                        @click="submitForm"-->
<!--                                >-->
<!--                                    Создать и редактировать-->
<!--                                </v-btn>-->
                                <v-btn
                                        class="mt-4"
                                        color="primary"
                                        outlined
                                        @click="closeDialog"
                                        depressed
                                >
                                    Отмена
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>
                </v-form>

            </v-card>



        </v-dialog>
    </div>
</template>

<script>

    import * as ExternalRules from "@/rules/ExternalRules";
    import EmployeeDataService from "@/services/EmployeeDataService";
    import RolesDataService from "@/services/RolesDataService";


    // let options = {
    //     year: 'numeric',
    //     month: 'numeric',
    //     day: 'numeric',
    //     timezone: 'UTC'
    // };

    export default {
        name: "AddEmployeeForm",

        data () {
            return {
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'DD.MM.YYYY',
                },

                name: null,
                surname: null,
                secondname: null,
                gender: null,
                password: null,
                passwordConfirm: null,
                birthday: null,
                post: null,
                phone: null,
                email: null,
                externalRules: ExternalRules,
                genderSelect: [
                    { label: 'Мужской', value: 'M' },
                    { label: 'Женский', value: 'F' }
                ],

                //date: new Date().toISOString().split('T')[0], //Дата в формате YYYY-MM-DD
                //date: new Date().toLocaleString("ru", options),

                publicPath: process.env.BASE_URL,
                validForm: true,
                loading: false,
                roleItems: [],
                roleIds: null, //массив Id ролей, выбранных при создании сотрудника
            }
        },

        watch: {

            isModalOpen(newValue) {
                if (newValue) {
                    //this.resetForm() // Сбрасываем форму при открытии диалога
                    this.getRoles() //Запрашиваем роли. Пробуем новый подход - не в mounted
                }
            }

        },

        computed: {
            passwordConfirmationRule() {
                return () =>
                    this.password === this.passwordConfirm || "Пароли должны совпадать";
            },

            // Проверка состояния диалога открыт/закрыт в VUEX
            isModalOpen() {
                return this.$store.getters.isAddEmployeeDialogOpen;
            },
        },

        methods: {
            closeDialog() {
                this.$store.dispatch('closeAddEmployeeDialog');
            },

            resetForm() {
                this.name = null,
                this.surname = null,
                this.secondname = null,
                this.gender = null,
                this.password = null,
                this.passwordConfirm = null,
                this.birthday = null,
                this.post = null,
                this.phone = null,
                this.email = null
                this.validForm = true,
                this.loading = false,
                this.roleItems = [],
                this.roleIds = null
            },

            submitForm() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    let data = {
                        name: this.name,
                        surname: this.surname,
                        secondname: this.secondname,
                        gender: this.gender,
                        username: this.email,
                        password: this.password,
                        birthday: this.birthday.split(".").reverse().join("-"), //Преобразуем в дату ISO формата
                        post: this.post,
                        phone: this.phone,
                        email: this.email,
                        roles: this.roleIds,//Массив - выбранные роли сотрудника
                    }

                    EmployeeDataService.create(data)
                        .then(response => {
                            this.$router.push({ name: 'EditEmployee', params: { id : response.data.id }})
                            // this.$emit('update-accounts-table') //Генерируем событие - Обновить лист счетов
                            // this.showDialog = false // Закрываем диалог
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            },

            getRoles() {
                RolesDataService.find()
                    .then(response => {
                        this.roleItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        },
        mounted () {

        }
    }
</script>

<style scoped>


</style>