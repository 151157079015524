<template>
        <div class="d-flex align-center justify-center" style="height: 90vh;">
            <div class="text-center">
                <div class="d-flex flex-column align-center">
                    <v-img
                            :src="`${publicPath}avatars/11.svg`"
                            width="200"
                    ></v-img>
                    <h1 class="mt-5">Ой, дружок! Такой страницы не существует.</h1>
<!--                            <router-link to="/">Вернуться на главную</router-link>-->
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: "NotFound",
        data () {
            return {
                publicPath: process.env.BASE_URL,
            }
        },
    }
</script>

<style scoped>

</style>