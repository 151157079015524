var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AddTaskForm',{on:{"updateTasks":_vm.get}}),_c('v-row',[_c('v-col',[_c('span',{staticStyle:{"font-size":"25px","font-weight":"300"}},[_vm._v("Задачи")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.addTask}},[_vm._v(" Создать ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',{attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticStyle:{"padding-top":"0","padding-bottom":"15px"}},[_c('div',{staticClass:"data-label"},[_vm._v("Статус")]),_vm._l((_vm.status),function(item){return _c('div',{key:item.val},[_c('v-checkbox',{attrs:{"value":item.val,"label":("" + (item.text)),"hide-details":"true"},model:{value:(_vm.statusArr),callback:function ($$v) {_vm.statusArr=$$v},expression:"statusArr"}})],1)})],2)],1),_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.resetForm}},[_vm._v(" Сбросить ")]),_c('v-btn',{attrs:{"disabled":!_vm.valid,"depressed":"","color":"primary"},on:{"click":_vm.get}},[_vm._v(" Применить ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"items-per-page":50,"footer-props":{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        },"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                        var item = ref.item;
return [_c('router-link',{staticClass:"itemlink",class:{done : item.status == 1},attrs:{"to":{ name: 'EditTask', params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.importance == 1)?_c('v-icon',{attrs:{"color":"red","size":"20"}},[_vm._v(" mdi-fire ")]):_vm._e()]}},{key:"item.end_date.date",fn:function(ref){
                        var item = ref.item;
return [_c('div',{class:{enddeadline: _vm.getDeadlineStatus(item.end_date.date, item.status) == true}},[_vm._v(" "+_vm._s(_vm.formatDeadlineDate (item.end_date.date, item.status ))+" ")])]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_c('task-stepper',{attrs:{"statusid":item.status}})]}},{key:"item.order_number",fn:function(ref){
                        var item = ref.item;
return [_c('router-link',{staticClass:"itemlink",attrs:{"to":{ name: 'EditContract', params: { id: item.order_id }}}},[_vm._v(" "+_vm._s(item.order_number)+" ")])]}},{key:"item.create_employee_name",fn:function(ref){
                        var item = ref.item;
return [_c('v-avatar',{staticStyle:{"margin-right":"5px"},attrs:{"size":"30"}},[_c('img',{attrs:{"src":("" + _vm.avatars + (item.create_employee_avatar))}})]),_vm._v(" "+_vm._s(item.create_employee_name)+" ")]}},{key:"item.responsible_employee_name",fn:function(ref){
                        var item = ref.item;
return [_c('v-avatar',{staticStyle:{"margin-right":"5px"},attrs:{"size":"30"}},[_c('img',{attrs:{"src":("" + _vm.avatars + (item.responsible_employee_avatar))}})]),_vm._v(" "+_vm._s(item.responsible_employee_name)+" ")]}},{key:"item.create_date.date",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.create_date.date))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }