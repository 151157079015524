<template>
  <div>

<!--  Диалог отправки бизнесс карты-->
      <v-dialog
              v-model="sendBizcardDialog"
              max-width="600"
      >
          <v-card>
              <v-card-title class="text-h5">
                  Отправка SMS визитки
                  <v-spacer></v-spacer>
                  <v-btn
                          icon
                          @click="sendBizcardDialog = false"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-form
                      ref="form"
                      v-model="validForm"
                      lazy-validation
              >
              <v-card-text>
                      <v-text-field
                              label="Телефон"
                              v-mask="'+7 (###) ###-##-##'"
                              :rules="externalRules.phoneRules.concat(externalRules.requiredRules)"
                              id="phonenumber"
                              v-model="smsphone"
                              required
                              class="mb-5"
                      ></v-text-field>
                      <v-textarea
                              name="input-7-1"
                              label="Текст визитки"
                              :hint="`${Math.ceil(smstext.length/70)} SMS (${smstext.length}/${Math.ceil(smstext.length/70)*70})`"
                              persistent-hint
                              v-model="smstext"
                              required
                              :rules="externalRules.requiredRules"
                      ></v-textarea>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center">
                      <v-btn
                              depressed
                              color="primary"
                              @click="sendSMS"
                              class="mb-8"
                              x-large
                      >
                          Отправить
                      </v-btn>
                  </v-card-actions>
              </v-form>
          </v-card>
      </v-dialog>

      <v-snackbar
              v-model="smsSendingSnackbar"
              color="green"
              top
      >
          Сообщение отправлено
      </v-snackbar>
      <v-snackbar
              v-model="smsNotSendingSnackbar"
              color="red"
              top
      >
          Сообщение не отправлено
      </v-snackbar>


      <v-row>
          <v-col>



<!--<chart></chart>-->

<!--              <MasonryInfiniteGrid>-->




<!--                  <v-card-->

<!--                  >-->
<!--&lt;!&ndash;                      я участник и договор в работе, можно свернуть, количество указать&ndash;&gt;-->
<!--                      <v-card-text>Мои проекты в работе</v-card-text>-->
<!--                  </v-card>-->


<!--                  <v-card-->

<!--                  >-->
<!--                      <v-card-text>Сообщения</v-card-text>-->
<!--                  </v-card>-->
<!--              </MasonryInfiniteGrid>-->


                <div v-if="VUE_APP_TENANT !== 'GEOREESTR'">
              <div class=" d-flex flex-wrap justify-space-between">
                  <v-card
                          min-width="344"
                          :to="{ name: 'Tasks' }"
                          class="task-background mb-6"
                          elevation="0"
                  >
                    <v-card-title style="color: white">Задачи</v-card-title>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-text style="color: white">
                          <p style="font-weight: bold; font-size: 35px">{{ getTaskOverdue }}</p>
                          <p style="font-size: 13px">Просрочены</p>
                          <p style="font-size: 25px">{{ getTaskInWork }}</p>
                          <p style="font-size: 13px">Ожидают выполнения</p>
                      </v-card-text>
                  </v-card>


                  <v-card
                          min-width="344"
                          elevation="0"
                          :to="{ name: 'Contracts' }"
                          class="contract-background mb-6"
                  >
                      <v-card-title style="color: white">Договоры</v-card-title>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-text style="color: white">
                          <p style="font-weight: bold; font-size: 35px">{{ getFireOrder }}</p>
                          <p style="font-size: 13px">Просрочены</p>
                          <p style="font-size: 30px">{{ getOrderInworkStatus }}</p>
                          <p style="font-size: 13px">В работе</p>
                          <p style="font-size: 25px">{{ getOrderNewStatus }}</p>
                          <p style="font-size: 13px">Новые</p>
                      </v-card-text>
                  </v-card>

                  <v-card
                          min-width="344"
                          elevation="0"
                          :to="{ name: 'Accounts' }"
                          class="account-background mb-6"
                  >
                      <v-card-title style="color: white">Счета</v-card-title>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-text style="color: white">
                          <p style="font-weight: bold; font-size: 35px">{{ getAccountOverdueCount }}</p>
                          <p style="font-size: 13px">Просрочены</p>
<!--                          <p style="font-size: 25px">{{ AccountAwaitingPayment }} <span style="font-size: 18px; font-weight: normal;">на сумму 145 000 ₽</span></p>-->
                          <p style="font-size: 25px">{{ AccountAwaitingPayment }}</p>
                          <p style="font-size: 13px">Ожидают оплаты</p>
                      </v-card-text>
                  </v-card>

                  <v-card
                          min-width="344"
                          elevation="0"
                          :to="{ name: 'SearchKamaz' }"
                          class="kamaz-background mb-6"
                  >
                      <v-card-title style="color: white">Найти КАМАЗ</v-card-title>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-text style="color: white;" class="d-flex justify-center align-center">
                          <p style="font-size: 30px; text-align: center;" class="mt-10">
                              <v-icon
                                      size="100"
                                      color="white"
                              >
                                  mdi-car-search-outline
                              </v-icon>
                          </p>

                      </v-card-text>
                  </v-card>

                  <v-card
                          min-width="344"
                          elevation="0"
                          @click="ShowSendSMSDialog()"
                          class="send-bizcard mb-6"
                  >
                      <v-card-title style="color: white">Отправить SMS визитку</v-card-title>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-text>
                        <div style="color: white;" class="d-flex justify-center align-center">
                              <v-icon
                                      size="100"
                                      color="white"
                              >
                                  mdi-send-circle-outline
                              </v-icon>
                        </div>
                      </v-card-text>
                  </v-card>

                  <v-card
                          min-width="344"
                          elevation="0"
                          :to="{ name: 'WorksReport' }"
                          class="work-report-background  mb-6"
                  >
                      <v-card-title style="color: white">Отчет по работам</v-card-title>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-text style="color: white;" class="d-flex justify-center align-center">
                          <p style="font-size: 30px; text-align: center;" class="mt-10">
                              <v-icon
                                      size="100"
                                      color="white"
                              >
                                  mdi-file-chart-outline
                              </v-icon>
                          </p>

                      </v-card-text>
                  </v-card>



<!--                  <v-card-->
<!--                          min-width="344"-->
<!--                          elevation="0"-->
<!--                          :to="{ name: 'Tasks' }"-->
<!--                          class="task-background mr-6"-->
<!--                  >-->
<!--                      <v-card-title style="color: white">Предложения</v-card-title>-->
<!--                      <v-divider class="mx-4"></v-divider>-->
<!--                      <v-card-text style="color: white">-->
<!--                          <p style="font-weight: bold; font-size: 35px">{{ getTaskInWork }}</p>-->
<!--                          <p style="font-size: 13px">Ожидает выполнения</p>-->
<!--                          <p style="font-size: 25px">{{ getTaskOverdue }}</p>-->
<!--                          <p style="font-size: 13px">Просрочены</p>-->
<!--                      </v-card-text>-->
<!--                  </v-card>-->

              </div>
                </div>
          </v-col>
      </v-row>


  </div>
</template>

<script>

    // import { MasonryInfiniteGrid } from "@egjs/vue-infinitegrid";
    // import { FrameInfiniteGrid } from "@egjs/vue-infinitegrid";
    // import Chart from "@/components/Chart";
import SendSMSDataService from "@/services/SendSMSDataService";
import * as ExternalRules from "@/rules/ExternalRules";

export default {
  name: 'Dashboard',
  components: {
      // MasonryInfiniteGrid,
      // Chart
  },
    data() {
      return {
          publicPath: process.env.BASE_URL,
          externalRules: ExternalRules,
          newWindow: null,
          sendBizcardDialog: false,
          smstext:  this.$store.state.auth.user.userName + ' ' + this.$store.state.auth.user.userSurName + '\n' +  this.$store.state.auth.user.post + '\n+7(473)229-60-61\nartgeocom@mail.ru',
          smsphone: '',
          validForm: true,
          smsSendingSnackbar: false,
          smsNotSendingSnackbar: false,
          VUE_APP_TENANT: process.env.VUE_APP_TENANT
      }
    },

    methods: {
        ShowSendSMSDialog() {
            this.sendBizcardDialog = true
        },
        getNewStatusContractsCount() {
            this.$store.dispatch('newStatusContractsCount')
        },

        getInworkStatusContractsCount() {
            this.$store.dispatch('inworkStatusContractsCount')
        },
        getAccountAwaitingPayment() {
            this.$store.dispatch('accountAwaitingPayment')
        },
        sendSMS() {
            if (this.$refs.form.validate()) {
                let data = {
                    phone: this.smsphone,
                    text: this.smstext
                }
                SendSMSDataService.sendSMS(data)
                    .then(response => {
                        if (response.data.result === 1) {
                            this.sendBizcardDialog = false
                            this.smsSendingSnackbar = true
                        } else {
                            this.sendBizcardDialog = false
                            this.smsNotSendingSnackbar = true
                        }
                    })
                    .catch(error => {
                        this.sendBizcardDialog = false
                        this.smsNotSendingSnackbar = true
                        console.log(error);
                    })
            }
        }
    },

    computed: {

        //Просроченные задачи
        getTaskOverdue() {
            return this.$store.state.task.taskOverdueCount
        },

        //Задачи в работе
        getTaskInWork() {
            return this.$store.state.task.taskInWorkCount
        },

        //Количество договоров со статусом - Новые
        getOrderNewStatus() {
            return this.$store.state.contract.newStatusContractsCount
        },

        //Количество договоров со статусом - В работе
        getOrderInworkStatus() {
            return this.$store.state.contract.inworkStatusContractsCount
        },

        //Количество просроченных договоров
        getFireOrder() {
            return this.$store.state.contract.fireContractsCount
        },

        //Количество Просроченные счетов
        getAccountOverdueCount() {
            return this.$store.state.account.accountOverdueCount
        },

        //Количество счетов со статусом - Ожидают оплату
        AccountAwaitingPayment() {
            return this.$store.state.account.accountAwaitingPayment
        }
    },

    mounted() {
         this.getNewStatusContractsCount() //Количество договоров со статусом - Новые
         this.getInworkStatusContractsCount() //Количество договоров со статусом - В работе
         this.getAccountAwaitingPayment() //Количество счетов со статусом - Ожидают оплату
    },
}
</script>
<style >
    .task-background {
        background: linear-gradient(318deg, rgba(79,238,220,1) 0%, rgba(45,66,253,1) 100%);
    }

    .contract-background {
        background: linear-gradient(318deg, rgb(251, 240, 182) 0%, rgb(164, 92, 28) 100%);
    }

    .account-background {
        background: linear-gradient(318deg, rgb(112, 247, 120) 0%, rgb(42, 128, 30) 100%);
    }

    .kamaz-background {
        background: linear-gradient(318deg, rgb(179, 238, 220) 0%, rgb(28, 164, 119) 100%)
    }


    .work-report-background {
        background: linear-gradient(318deg, rgb(179, 238, 220) 0%, rgb(28, 164, 119) 100%)
    }
    .send-bizcard {
        background: linear-gradient(318deg, rgb(255, 140, 238) 0%, rgb(104, 40, 198) 100%)
    }


    #phonenumber {
        max-height: 70px;
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        color: #313131;
    }

</style>
