export default {
    state: {
        isAddCustomerDialogOpen: false
    },
    actions: {
        showAddCustomerDialog({ commit }) {
            commit('OPEN_ADD_CUSTOMER_DIALOG');
        },
        closeAddCustomerDialog({ commit }) {
            commit('CLOSE_ADD_CUSTOMER_DIALOG');
        }
    },
    mutations: {
        OPEN_ADD_CUSTOMER_DIALOG(state) {
            state.isAddCustomerDialogOpen = true
        },
        CLOSE_ADD_CUSTOMER_DIALOG(state) {
            state.isAddCustomerDialogOpen = false
        }
    },

    getters: {
        isAddCustomerDialogOpen: state => {
            return state.isAddCustomerDialogOpen
        },
    }
}