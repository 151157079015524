<template>
    <div class="text-right">
        <v-dialog
                v-model="isModalOpen"
                persistent
                width="600"
        >
            <v-card>
                <v-card-title class="headline">
                    Новый клиент
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-tabs
                                        v-model="customertype"
                                >
                                    <v-tab>Физическое лицо</v-tab>
                                    <v-tab v-if="allow_create_ul">Юридическое лицо</v-tab>
                                    <!-- Физическое лицо-->
                                    <v-tab-item>
                                        <v-form
                                                ref="pForm"
                                                v-model="phisicalFace.validForm"
                                                lazy-validation
                                        >
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col>
<!--                                                        <v-select-->
<!--                                                                v-model="phisicalFace.select"-->
<!--                                                                :items="phisicalFace.clienttype"-->
<!--                                                                item-text="label"-->
<!--                                                                item-value="value"-->
<!--                                                                return-object-->
<!--                                                        ></v-select>-->

<!--                                                        Переделал выбор списка через пропсы-->
                                                        <v-select
                                                                v-model="pfClienttype"
                                                                :items="phisicalFace.clienttype"
                                                                item-text="label"
                                                                item-value="value"
                                                                return-object
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.surname"
                                                                label="Фамилия"
                                                                :rules="requiredRules"
                                                                required
                                                                @input="checkFL"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="4"
                                                    >
                                                        <v-text-field
                                                                v-model="phisicalFace.name"
                                                                label="Имя"
                                                                :rules="requiredRules"
                                                                required
                                                                @input="checkFL"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="4"
                                                    >
                                                        <v-text-field
                                                                v-model="phisicalFace.secondname"
                                                                label="Отчество"
                                                        ></v-text-field>
                                                    </v-col>

                                                </v-row>


                                                <v-row v-for="(row, index) in phisicalFace.phones" :key="index">
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="row.phonenumber"
                                                                :rules="phoneRules"
                                                                :key="index"
                                                                label="Телефон"
                                                                v-mask="'+7 (###) ###-##-##'"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="row.description"
                                                                label="Комментарий"
                                                                :append-outer-icon="'mdi-plus'"
                                                                @click:append-outer="addPPhone"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-for="(row, index) in phisicalFace.emails" :key="index">
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="row.email"
                                                                :rules="emailRules"
                                                                :key="index"
                                                                label="E-mail"
                                                                type="email"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="row.description"
                                                                label="Комментарий"
                                                                :append-outer-icon="'mdi-plus'"
                                                                @click:append-outer="addPEmail"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.birthday"
                                                                :rules="dateRules"
                                                                label="Дата рождения"
                                                                v-mask="'##.##.####'"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.snils"
                                                                label="СНИЛС"
                                                                v-mask="'###-###-### ##'"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-show="false">
                                                    <v-col>
                                                        <v-autocomplete
                                                                placeholder="Введите не менее 3-х символов для поиска"
                                                                clearable
                                                                v-model="phisicalFace.companycode"
                                                                :items="items"
                                                                :search-input.sync="search"
                                                                item-value="customerid"
                                                                item-text="fullname"
                                                                label="Компания"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-show="false">
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.post"
                                                                label="Должность"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <p style="font-weight: bold; padding: 0; margin: 0">Паспортные данные</p>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.passportseries"
                                                                label="Серия"
                                                                v-mask="'## ##'"
                                                                persistent-hint

                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.passportnumber"
                                                                label="Номер"
                                                                v-mask="'######'"

                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.passportdata"
                                                                :rules="dateRules"
                                                                label="Дата выдачи"
                                                                v-mask="'##.##.####'"

                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.passportgivenout"
                                                                label="Кем выдан"

                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <p style="font-weight: bold; padding: 0; margin: 0">Адрес регистрации</p>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.adressregregion"
                                                                label="Регион"
                                                                persistent-hint
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.adressregdistrict"
                                                                label="Район"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.adressregsettlementpoint"
                                                                label="Населенный пункт"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.adressregstreet"
                                                                label="Улица"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.adressreghouse"
                                                                label="Дом"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.adressregcorps"
                                                                label="Корпус"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.adressregbuild"
                                                                label="Строение"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="phisicalFace.adressregflat"
                                                                label="Квартира"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-textarea
                                                                v-model="phisicalFace.description"
                                                                label="Примечания"
                                                                rows="2"
                                                        ></v-textarea>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
<!--                                                        <v-checkbox-->
<!--                                                                v-model="computedPhisicalFaceIsSendEmail"-->
<!--                                                                label="Уведомить пользователя о создании учётной записи на e-mail"-->
<!--                                                                :disabled="!phisicalFace.email"-->
<!--                                                        ></v-checkbox>-->
<!--                                                        <v-checkbox-->
<!--                                                                v-model="computedPhisicalFaceIsSendSMS"-->
<!--                                                                label="Уведомить пользователя о создании учётной записи по телефону (sms)"-->
<!--                                                                :disabled="!phisicalFace.phones[0].phonenumber"-->
<!--                                                        ></v-checkbox>-->

                                                        <!-- Если Клиент уже содержится в базе выдаем список-->
                                                        <div
                                                                v-show="checkCustomerFLItems.length > 0"
                                                                style="margin-bottom: 20px;"
                                                        >
                                                            <p style="padding: 2px; border-radius: 5px; text-align: center; font-size: 13px; background-color: #ec2e2e; color: white">Обратите внимание, возможно такой клиент уже содержится в базе данных!</p>
                                                            <template>
                                                                <v-simple-table dense>
                                                                    <template v-slot:default>
                                                                        <thead>
                                                                        <tr>
                                                                            <th class="text-left">
                                                                                Код
                                                                            </th>
                                                                            <th class="text-left">
                                                                                ФИО
                                                                            </th>
                                                                            <th class="text-left">
                                                                                Дата рождения
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr
                                                                                v-for="item in checkCustomerFLItems"
                                                                                :key="item.customerid"
                                                                        >
                                                                            <td>{{ item.customerid }}</td>
                                                                            <td><router-link class="itemlink" :to="{ name: 'EditCustomer', params: { id: item.customerid }}">{{ item.fullname }}</router-link></td>
                                                                            <td>{{ item.birthday }}</td>

                                                                        </tr>
                                                                        </tbody>
                                                                    </template>
                                                                </v-simple-table>
                                                            </template>
                                                        </div>


                                                        <v-btn
                                                                class="mr-3 mt-4"
                                                                color="primary"
                                                                depressed
                                                                :disabled="!phisicalFace.validForm"
                                                                :loading="loading"
                                                                @click="submitPhisicalFace"
                                                        >
                                                            Создать
                                                        </v-btn>
                                                        <v-btn
                                                                class="mt-4"
                                                                color="primary"
                                                                outlined
                                                                @click="closeDialog"
                                                                depressed
                                                        >
                                                            Отмена
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-tab-item>
                                    <!--Юридическое лицо-->
                                    <v-tab-item v-if="allow_create_ul">
                                        <v-form
                                                ref="lForm"
                                                v-model="legalFace.validForm"
                                                lazy-validation
                                        >
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col>
<!--                                                        <v-select-->
<!--                                                                v-model="legalFace.select"-->
<!--                                                                :items="legalFace.clienttype"-->
<!--                                                                item-text="label"-->
<!--                                                                item-value="value"-->
<!--                                                                return-object-->
<!--                                                        ></v-select>-->
                                                        <!--Переделал выбор списка через пропсы-->
                                                        <v-select
                                                                v-model="lfClienttype"
                                                                :items="legalFace.clienttype"
                                                                item-text="label"
                                                                item-value="value"
                                                                return-object
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.inn"
                                                                label="ИНН"
                                                                :rules="requiredRules"
                                                                v-mask="'#############'"
                                                                @input="checkUL"
                                                                :error-messages="checkCustomerErrorMessages"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.surname"
                                                                label="Сокращенное наименование"
                                                                :rules="requiredRules"
                                                                required
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-for="(row, index) in legalFace.phones" :key="index">
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="row.phonenumber"
                                                                :rules="phoneRules"
                                                                :key="index"
                                                                label="Телефон"
                                                                v-mask="'+7 (###) ###-##-##'"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="row.description"
                                                                label="Комментарий"
                                                                :append-outer-icon="'mdi-plus'"
                                                                @click:append-outer="addLPhone"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-for="(row, index) in legalFace.emails" :key="index">
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="row.email"
                                                                :rules="emailRules"
                                                                :key="index"
                                                                label="E-mail"
                                                                type="email"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="row.description"
                                                                label="Комментарий"
                                                                :append-outer-icon="'mdi-plus'"
                                                                @click:append-outer="addLEmail"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.ogrn"
                                                                label="ОГРН"
                                                                v-mask="'#############'"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.kpp"
                                                                label="КПП"
                                                                v-mask="'#########'"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.settlementaccount"
                                                                label="Расчетный счет"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.correspondentaccount"
                                                                label="Корреспондентский счет"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.bankname"
                                                                label="Наименование банка"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.bik"
                                                                label="БИК"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.adressregindex"
                                                                label="Индекс"
                                                                hint="Юридический адрес"
                                                                persistent-hint
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.adressregregion"
                                                                label="Регион"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.adressregdistrict"
                                                                label="Район"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.adressregsettlementpoint"
                                                                label="Населенный пункт"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.adressregstreet"
                                                                label="Улица"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.adressreghouse"
                                                                label="Дом"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.adressregcorps"
                                                                label="Корпус"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.adressregbuild"
                                                                label="Строение"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                                v-model="legalFace.adressregflat"
                                                                label="Офис"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-textarea
                                                                v-model="legalFace.description"
                                                                label="Примечания"
                                                                rows="2"
                                                        ></v-textarea>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
<!--                                                        <v-checkbox-->
<!--                                                                v-model="computedLegalFaceIsSendEmail"-->
<!--                                                                label="Уведомить пользователя о создании учётной записи на e-mail"-->
<!--                                                                :disabled="!legalFace.email"-->
<!--                                                        ></v-checkbox>-->
<!--                                                        <v-checkbox-->
<!--                                                                v-model="computedLegalFaceIsSendSMS"-->
<!--                                                                label="Уведомить пользователя о создании учётной записи по телефону (sms)"-->
<!--                                                                :disabled="!legalFace.phones[0].phonenumber"-->
<!--                                                        ></v-checkbox>-->
                                                        <!-- Если Клиент уже содержится в базе показываем его-->
                                                        <div
                                                                v-show="checkCustomerItems.length > 0"
                                                                style="margin-bottom: 20px;"
                                                        >
                                                            <p style="padding: 2px; border-radius: 5px; text-align: center; font-size: 13px; background-color: #ec2e2e; color: white">Обратите внимание, такой клиент уже содержится в базе данных!</p>
                                                            <template>
                                                                <v-simple-table dense>
                                                                    <template v-slot:default>
                                                                        <thead>
                                                                        <tr>
                                                                            <th class="text-left">
                                                                                Код
                                                                            </th>
                                                                            <th class="text-left">
                                                                                Наименование
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr
                                                                                v-for="item in checkCustomerItems"
                                                                                :key="item.customerid"
                                                                        >
                                                                            <td>{{ item.customerid }}</td>
                                                                            <td><router-link class="itemlink" :to="{ name: 'EditCustomer', params: { id: item.customerid }}">{{ item.fullname }}</router-link></td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </template>
                                                                </v-simple-table>
                                                            </template>
                                                        </div>
                                                        <v-btn
                                                                class="mr-3 mt-4"
                                                                color="primary"
                                                                :disabled="!legalFace.validForm"
                                                                :loading="loading"
                                                                @click="submitLegalFace"
                                                                depressed
                                                        >
                                                            Создать
                                                        </v-btn>
                                                        <v-btn
                                                            class="mt-4"
                                                            color="primary"
                                                            outlined
                                                            @click="closeDialog"
                                                            depressed
                                                        >
                                                            Отмена
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-tab-item>
                                </v-tabs>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import CustomerDataService from "../services/CustomerDataService";
    import FnsAPIDataService from "@/services/FnsAPIDataService";
    export default {
        name: "AddCustomerForm",
        props: {
            //Позволять создавать ЮЛ - Показать вкладку создания ЮЛ (Необходимо не отображать когда создаем контактное лицо при добавлении контактного лица из ЮЛ)
            allow_create_ul: {
                type: Boolean,
                default: true
            },
            pfClienttype: {
                type: String,
                default: 'CL'
            },
            lfClienttype: {
                type: String,
                default: 'CL'
            },
        },
        data () {
            return {
                loading: false,
                customertype: null,
                phisicalFace: {
                    validForm: true,
                    //select: 'CL',
                    clienttype: [
                        { label: 'Клиент', value: 'CL' },
                        { label: 'Подрядчик', value: 'PD' },
                        { label: 'Контактное лицо', value: 'KF' }
                    ],
                    name: null,
                    secondname: null,
                    surname: null,
                    phones: [
                        { phonenumber: '', description: '' }
                    ],
                    emails: [
                        { email: '', description: '' }
                    ],
                    birthday: null,
                    snils: null,
                    post: null,
                    passportseries: null,
                    companycode: null,
                    passportnumber: null,
                    passportdata: null,
                    passportgivenout: null,
                    adressregregion: null,
                    adressregdistrict: null,
                    adressregsettlementpoint: null,
                    adressregstreet: null,
                    adressreghouse: null,
                    adressregcorps: null,
                    adressregbuild: null,
                    adressregflat: null,
                    description: null,
                    isSendEmail: false,
                    isSendSMS: false
                },
                legalFace: {
                    validForm: true,
                    //select: 'CL',
                    clienttype: [
                        { label: 'Клиент', value: 'CL' },
                        { label: 'Подрядчик', value: 'PD' },
                        { label: 'Эксплуатирующая организация', value: 'OU' }
                    ],
                    surname: null,
                    phones: [
                        { phonenumber: '', description: '' }
                    ],
                    emails: [
                        { email: '', description: '' }
                    ],
                    ogrn: null,
                    inn: null,
                    kpp: null,
                    settlementaccount: null,
                    correspondentaccount: null,
                    bankname: null,
                    bik: null,
                    adressregindex: null,
                    adressregregion: null,
                    adressregdistrict: null,
                    adressregsettlementpoint: null,
                    adressregstreet: null,
                    adressreghouse: null,
                    adressregcorps: null,
                    adressregbuild: null,
                    adressregflat: null,
                    description: null,
                    isSendEmail: false,
                    isSendSMS: false
                },
                requiredRules: [
                    v => !!v || 'Пожалуйста, заполните это поле',
                ],
                phoneRules: [
                    v => (!v || v.length === 18) || 'Пожалуйста, введите телефон',
                ],
                emailRules: [
                    v => (!v || /.+@.+\..+/.test(v)) || 'Пожалуйста, введите адрес электронной почты',
                ],
                dateRules: [
                    v => (!v || /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(v)) || 'Пожалуйста, введите дату',
                ],
                items: [],
                checkCustomerItems: [],
                checkCustomerFLItems: [],
                checkCustomerErrorMessages: '',
                search: null,
            }
        },
        watch: {
            search() {
                if (this.items.length > 0) return

                if (this.search.length > 3) {
                    CustomerDataService.findBySurname(this.search, 'L')
                        .then(response => {
                            this.items = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },

            isModalOpen(newValue) {
                if (newValue) {
                    //this.resetForm() // Сбрасываем форму при открытии диалога
                }
            }
        },
        computed: {
            // Проверка состояния диалога открыт/закрыт в VUEX
            isModalOpen() {
                return this.$store.getters.isAddCustomerDialogOpen;
            },

            pfClientType() {
                if (this.allow_create_ul === false) { return { label: 'Контактное лицо', value: 'KF' }  } else {return { label: 'Клиент', value: 'CL' }}
            },

            // computedLegalFaceIsSendEmail() {
            //     if (this.legalFace.email) {
            //         return true
            //     } else
            //     {
            //         return false
            //     }
            // },
            // computedPhisicalFaceIsSendEmail() {
            //     if (this.phisicalFace.email) {
            //         return true
            //     } else
            //     {
            //         return false
            //     }
            // },
            computedPhisicalFaceIsSendSMS() {
                if (this.phisicalFace.phones[0].phonenumber) {
                    return true
                } else
                {
                    return false
                }
            },
            computedLegalFaceIsSendSMS() {
                if (this.legalFace.phones[0].phonenumber) {
                    return true
                } else
                {
                    return false
                }
            }
        },

        methods: {

            resetForm() {
                this.loading = false
                this.customertype = null
                this.pfClienttype = 'CL'
                this.lfClienttype = 'CL'
                this.phisicalFace = {
                    validForm: true,
                        clienttype: [
                        { label: 'Клиент', value: 'CL' },
                        { label: 'Подрядчик', value: 'PD' },
                        { label: 'Контактное лицо', value: 'KF' }
                    ],
                        name: null,
                        secondname: null,
                        surname: null,
                        phones: [
                        { phonenumber: '', description: '' }
                    ],
                        emails: [
                        { email: '', description: '' }
                    ],
                        birthday: null,
                        snils: null,
                        post: null,
                        passportseries: null,
                        companycode: null,
                        passportnumber: null,
                        passportdata: null,
                        passportgivenout: null,
                        adressregregion: null,
                        adressregdistrict: null,
                        adressregsettlementpoint: null,
                        adressregstreet: null,
                        adressreghouse: null,
                        adressregcorps: null,
                        adressregbuild: null,
                        adressregflat: null,
                        description: null,
                        isSendEmail: false,
                        isSendSMS: false
                },
                    this.legalFace = {
                    validForm: true,
                        //select: 'CL',
                        clienttype: [
                        { label: 'Клиент', value: 'CL' },
                        { label: 'Подрядчик', value: 'PD' },
                        { label: 'Эксплуатирующая организация', value: 'OU' }
                    ],
                        surname: null,
                        phones: [
                        { phonenumber: '', description: '' }
                    ],
                        emails: [
                        { email: '', description: '' }
                    ],
                        ogrn: null,
                        inn: null,
                        kpp: null,
                        settlementaccount: null,
                        correspondentaccount: null,
                        bankname: null,
                        bik: null,
                        adressregindex: null,
                        adressregregion: null,
                        adressregdistrict: null,
                        adressregsettlementpoint: null,
                        adressregstreet: null,
                        adressreghouse: null,
                        adressregcorps: null,
                        adressregbuild: null,
                        adressregflat: null,
                        description: null,
                        isSendEmail: false,
                        isSendSMS: false
                }
            },

            //Пользовательское событие при успешном сохранении клиента. Это событие слушаем в родительском компоненте
            //customerid - id созданного клиента
            createEvent(customerid) {
                this.closeDialog()
                this.$emit('create-customer-event', customerid)
            },

            closeDialog() {
                this.$store.dispatch('closeAddCustomerDialog');
            },

            //Проверка, что в БД нет БЛ с таким ИНН
            checkUL() {
                this.legalFace.validForm = true
                this.checkCustomerErrorMessages = ''
                //Если поле ИНН заполнено
                if (this.legalFace.inn.length >= 10) {
                    CustomerDataService.findBySurname('', 'L', '', this.legalFace.inn)
                        .then(response => {
                            this.checkCustomerItems = response.data
                            //Если ЮЛ найдено
                            if (this.checkCustomerItems.length > 0) {
                                this.legalFace.validForm = false
                                this.checkCustomerErrorMessages = 'Клиент с таким ИНН уже существует'
                            } else { //Обращаемся к API серверу и считываем данные о клиенте
                                this.getFNSData(this.legalFace.inn)
                            }
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },

            //Проверка, есть ли в БД ФЛ с таким именем и фамилией. Если есть выдаем список
            checkFL() {
                // this.legalFace.validForm = true
                // this.checkCustomerErrorMessages = ''
                //Если заполнены поля Имя и Фамилия (более 3-х символов)
                if (this.phisicalFace.surname.length >= 3 && this.phisicalFace.name.length >= 3) {
                    CustomerDataService.findBySurname(this.phisicalFace.surname + ' ' + this.phisicalFace.name, 'P', '','')
                        .then(response => {
                            this.checkCustomerFLItems = response.data
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },


            //Запрос данных ФНС по ИНН
            getFNSData(inn) {
                FnsAPIDataService.getCompany(inn)
                    .then(response => {
                        this.legalFace.ogrn = response.data.data.ОГРН
                        this.legalFace.kpp = response.data.data.КПП
                        this.legalFace.surname = response.data.data.НаимСокр
                        // response.data.data.Контакты.Тел.forEach((item,i) => { //Заполняем телефоны
                        //     this.legalFace.phones[i].phonenumber = item
                        //     this.addLPhone()
                        // })
                        // response.data.data.Контакты.Емэйл.forEach((item,i) => { //Заполняем e-mailы
                        //     this.legalFace.emails[i].email = item
                        //     this.addLEmail()
                        // })
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            submitPhisicalFace: function () {
                if (this.$refs.pForm.validate()) {
                    this.loading = true
                    var data = {
                        customertype: 'P',
                        // clienttype: this.phisicalFace.select.value,
                        clienttype: this.pfClienttype,
                        name: this.phisicalFace.name,
                        secondname: this.phisicalFace.secondname,
                        surname: this.phisicalFace.surname,
                        phones: this.phisicalFace.phones,
                        emails: this.phisicalFace.emails,
                        birthday: this.phisicalFace.birthday,
                        snils: this.phisicalFace.snils,
                        post: this.phisicalFace.post,
                        companycode: this.phisicalFace.companycode,
                        passportseries: this.phisicalFace.passportseries,
                        passportnumber: this.phisicalFace.passportnumber,
                        passportdata: this.phisicalFace.passportdata,
                        passportgivenout: this.phisicalFace.passportgivenout,
                        adressregregion: this.phisicalFace.adressregregion,
                        adressregdistrict: this.phisicalFace.adressregdistrict,
                        adressregsettlementpoint: this.phisicalFace.adressregsettlementpoint,
                        adressregstreet: this.phisicalFace.adressregstreet,
                        adressreghouse: this.phisicalFace.adressreghouse,
                        adressregcorps: this.phisicalFace.adressregcorps,
                        adressregbuild: this.phisicalFace.adressregbuild,
                        adressregflat: this.phisicalFace.adressregflat,
                        description: this.phisicalFace.description,
                        isSendEmail: this.phisicalFace.isSendEmail,
                        isSendSMS: this.phisicalFace.isSendSMS
                    }

                    CustomerDataService.create(data)
                        .then (response => {
                            console.log(response.data)
                            //this.$router.push({ name: 'EditCustomer', params: { id : response.data.id, showSendPassMessage: true }}) //Передаем параметр компоненту - Показать диалог отправки пароля от ЛК
                            this.createEvent(response.data.id);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            },
            submitLegalFace: function () {

                if (this.$refs.lForm.validate()) {
                    this.loading = true
                    var data = {
                        customertype: 'L',
                        //clienttype: this.legalFace.select.value,
                        clienttype: this.lfClienttype,
                        surname: this.legalFace.surname,
                        phones: this.legalFace.phones,
                        emails: this.legalFace.emails,
                        ogrn: this.legalFace.ogrn,
                        inn: this.legalFace.inn,
                        kpp: this.legalFace.kpp,
                        settlementaccount: this.legalFace.settlementaccount,
                        correspondentaccount: this.legalFace.correspondentaccount,
                        bankname: this.legalFace.bankname,
                        bik: this.legalFace.bik,
                        adressregindex: this.legalFace.adressregindex,
                        adressregregion: this.legalFace.adressregregion,
                        adressregdistrict: this.legalFace.adressregdistrict,
                        adressregsettlementpoint: this.legalFace.adressregsettlementpoint,
                        adressregstreet: this.legalFace.adressregstreet,
                        adressreghouse: this.legalFace.adressreghouse,
                        adressregcorps: this.legalFace.adressregcorps,
                        adressregbuild: this.legalFace.adressregbuild,
                        adressregflat: this.legalFace.adressregflat,
                        description: this.legalFace.description,
                        isSendEmail: this.legalFace.isSendEmail,
                        isSendSMS: this.legalFace.isSendSMS
                    }

                    CustomerDataService.create(data)
                        .then (response => {
                            console.log(response.data)
                            //this.$router.push({ name: 'EditCustomer', params: { id : response.data.id, showSendPassMessage: true }}) //Передаем параметр компоненту - Показать диалог отправки пароля от ЛК
                            this.createEvent(response.data.id);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            },
            addPPhone: function () {
                this.phisicalFace.phones.push({phonenumber: '', description: ''});
            },
            addLPhone: function () {
                this.legalFace.phones.push({phonenumber: '', description: ''});
            },
            addPEmail: function () {
                this.phisicalFace.emails.push({email: '', description: ''});
            },
            addLEmail: function () {
                this.legalFace.emails.push({email: '', description: ''});
            }
        }
    }
</script>

<style scoped>

</style>