<template>
    <div>
        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Предложения</span>
            </v-col>
            <v-col>
                <AddOfferForm/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                    <v-text-field
                            label="Номер"
                            placeholder="Введите данные для поиска"
                            v-model="numberString"
                            @keyup.enter="get"
                    ></v-text-field>
                    <v-text-field
                            label="Получатель"
                            placeholder="Введите данные для поиска"
                            v-model="customerString"
                            @keyup.enter="get"
                    ></v-text-field>
                    <v-text-field
                            label="Краткое содержание"
                            placeholder="Введите данные для поиска"
                            v-model="contentsString"
                            @keyup.enter="get"
                    ></v-text-field>
                    <v-row>
                        <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                            <v-text-field
                                    label="Дата"
                                    placeholder="Начало"
                                    v-model="startDate"
                                    v-mask="'##.##.####'"
                                    :rules="dateRules"
                                    ref="startDate"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                            <v-text-field
                                    placeholder="Окончание"
                                    v-model="endDate"
                                    v-mask="'##.##.####'"
                                    :rules="dateRules"
                                    ref="endDate"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                        <v-row>
                            <v-col style="padding-top: 0; padding-bottom: 15px">
                                <div class="data-label">Статус</div>
                                <div v-for="item in status" :key="item.val">
                                    <v-checkbox
                                            v-model="statusArr"
                                            :value="item.val"
                                            :label="`${item.text}`"
                                            hide-details="true"
                                    ></v-checkbox>
                                </div>
                            </v-col>
                        </v-row>



                        <v-row
                                align="center"
                                justify="space-around"
                                class="mt-8"
                        >
                            <v-btn
                                    depressed
                                    @click="resetForm"
                            >
                                Сбросить
                            </v-btn>
                            <v-btn
                                    depressed
                                    color="primary"
                                    @click="get"
                            >
                                Применить
                            </v-btn>
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col cols="10">
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                >

                    <template v-slot:item.number="{ item }">
                        <router-link
                                class="mycolor itemlink" :to="{ name: 'EditOffer', params: { id: item.id }}">{{ item.number }}
                        </router-link>
                    </template>

                    <template v-slot:item.date.date="{ item }">
                        {{ item.date.date | formatDate}}
                    </template>

                    <!--Статус-->
                    <template v-slot:item.status="{ item }">
                        <OfferStepper :status="item.status" :offerid="item.id"/>
                    </template>

                    <template v-slot:item.ordernumber="{ item }">
                        <router-link
                                class="itemlink" :to="{ name: 'EditContract', params: { id: item.orderid }}">{{ item.ordernumber }}
                        </router-link>
                    </template>

                    <template v-slot:item.sum="{ item }">
                        {{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(Number(item.sum)) }}
                    </template>



                    <template v-slot:item.customerfullname="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditCustomer', params: { id: item.customerid }}">{{ item.customerfullname }}</router-link>
                    </template>

                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AddOfferForm from "@/components/AddOfferForm";
    import OfferDataService from "@/services/OfferDataService";
    import OfferStepper from "@/components/elements/OfferStepper";

    export default {
        name: "OffersTable",
        components: {OfferStepper, AddOfferForm},
        data () {
            return {
                numberString: '',
                customerString: '',
                contentsString: '',
                loading: true,
                startDate: '',
                endDate: '',
                status: [
                    { text: 'Рассматривается', val: '1' },
                    { text: 'Завершено', val: '2' },
                    { text: 'Провалено', val: '3' }
                ],
                statusArr: [], //Массив поиска - статусы
                headers: [
                    {
                        text: 'Номер',
                        value: 'number',
                        width: 90
                    },
                    {
                        text: 'Дата',
                        value: 'date.date',
                        width: 100
                    },
                    {
                        text: 'Статус',
                        value: 'status',
                        width: 150
                    },
                    {
                        text: 'Цена',
                        value: 'sum',
                        width: 100
                    },
                    {
                        text: 'Договор',
                        value: 'ordernumber',
                        width: 100
                    },
                    {
                        text: 'Получатель',
                        value: 'customerfullname',
                        width: 300,
                        sortable: false
                    },
                    {
                        text: 'Ответственный',
                        value: 'employeefullname',
                        width: 300,
                        sortable: false
                    },
                    {
                        text: 'Краткое содержание',
                        value: 'contents',
                        sortable: false
                    },


                ],
                items: [],
                dateRules: [
                    v => (!v || /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(v)) || 'Пожалуйста, введите дату',
                ],
            }
        },
        mounted () {
            //read Localstorage
            if (localStorage.offerNumberString) {
                this.numberString = localStorage.offerNumberString
            }
            if (localStorage.offerCustomerString) {
                this.customerString = localStorage.offerCustomerString
            }
            if (localStorage.offerContentsString) {
                this.contentsString = localStorage.offerContentsString
            }
            if (localStorage.offerStartDate) {
                this.startDate = localStorage.offerStartDate
            }
            if (localStorage.offerEndDate) {
                this.endDate = localStorage.offerEndDate
            }
            if (localStorage.offers_status) {
                this.statusArr = JSON.parse(localStorage.getItem('offers_status'))
            }

            //Запрашиваем данные с сервера
            this.get()
        },
        filters: {
            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
        },
        methods: {
            resetForm: function () {
                this.numberString = ''
                this.customerString = ''
                this.contentsString = ''
                this.startDate = ''
                this.endDate = ''
                this.statusArr = []
            },
            get: function () {
                //Save Localstorage
                localStorage.offerNumberString = this.numberString
                localStorage.offerCustomerString = this.customerString
                localStorage.offerContentsString = this.contentsString
                localStorage.offerStartDate = this.startDate
                localStorage.offerEndDate = this.endDate
                localStorage.setItem('offers_status', JSON.stringify(this.statusArr))

                this.loading = true

                let params = {
                    numberString: this.numberString,
                    customerString: this.customerString,
                    contentsString: this.contentsString,
                    startDate: this.formatDateToIso(this.startDate),
                    endDate:this.formatDateToIso(this.endDate),
                    status: this.statusArr,
                }

                OfferDataService.find(params)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            formatDateToIso(dateString) {
                if (!dateString) return ''
                return [dateString.slice(6,10),dateString.slice(3,5),dateString.slice(0,2)].join('-')
            },
        }
    }
</script>

<style scoped>
    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 4px;
    }

    .mycolor {
        color: rgb(0, 124, 255) !important;
    }
</style>